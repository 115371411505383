<template>
  <v-menu v-if="showSelector" position="bottom" offset="3" open-on-click>
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        variant="text"
        append-icon="fa-regular fa-chevron-down"
        rounded="48px"
        :text="$store.state.session.session?.partner.display_name"
      />
    </template>

    <v-card class="scope-selector">
      <sw-input
        :model-value="inputVal"
        id="scope-search"
        icon="fa-regular fa-magnifying-glass"
        placeholder="Search"
        @update:model-value="onInputChange"
        @click.stop
      />

      <v-list density="compact">
        <template v-if="filteredPartners.length">
          <v-list-item
            v-for="(partner, idx) of filteredPartners"
            :key="idx"
            :title="partner.display_name"
            density="compact"
            @click="onClick(partner.name)"
          >
            <template v-slot:prepend>
              <sw-avatar :name="partner.display_name" small class="mr-2" />
            </template>
          </v-list-item>
        </template>

        <v-list-item v-else class="text-center">
          <small>No partner matches your filter</small>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>

  <span v-else>
    {{ $store.state.session.session?.partner.display_name }}
  </span>
</template>

<script setup lang="ts">
  import { ref, onBeforeMount } from "vue";
  import { SwAvatar, SwInput } from "sweepatic-shared/components";

  import { useStore } from "@/composables/store";
  import { getPartners, setCurrentPartner } from "@/services/partners.service";
  import { Partner } from "@/typings";

  let partners: Partner[] = [];

  const $store = useStore();

  const inputVal = ref("");
  const filteredPartners = ref<Partner[]>([]);

  const showSelector = ref(false);

  onBeforeMount(() => {
    getPartners().then((payload) => {
      partners = payload;

      filteredPartners.value = payload;

      showSelector.value = $store.getters["session/isAdmin"] || partners.length > 1;
    });
  });

  const onInputChange = (val = "") => {
    inputVal.value = val;

    if (!val) {
      return (filteredPartners.value = partners);
    }
    const searchTerm = inputVal.value.trim().toLowerCase();

    filteredPartners.value = partners.filter(({ display_name }) => {
      return display_name.toLowerCase().includes(searchTerm);
    });
  };

  const onClick = async (partnerName: string) => {
    await setCurrentPartner(partnerName);

    window.location.reload();
  };
</script>

<style lang="scss" scoped>
  span {
    color: #fff;
    letter-spacing: 0.0892857143em;
    text-transform: uppercase;
    font-size: 14px !important;
  }

  button {
    border-radius: 6px;
  }

  #scope-search {
    input {
      padding-top: 8px;
    }
  }

  .scope-selector {
    width: 224px;
    padding: 8px;
    border-radius: 8px;
    min-height: 160px;
    position: relative;

    .no-results {
      position: absolute;
      margin: auto;
      top: 50%;
      width: calc(100% - 16px);
      display: block;
      text-align: center;

      p {
        margin-bottom: 4px;

        .subtext {
          font-size: 14px;
        }

        &:not(.subtext) {
          color: $gray_80;
          font-size: 12px;
        }
      }
    }

    .v-list {
      padding: 0;
      margin: 0;
      margin-top: 8px;
      max-height: 240px;
      overflow: auto;

      .v-list-item-title {
        white-space: initial;
      }

      .v-list-item {
        padding: 6px 4px !important;
        min-height: 0;
        border-radius: 4px;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
          background: $gray_50;
          transition: 0.2s;
        }
      }
    }
  }
</style>
