import { App } from "vue";
import * as Sentry from "@sentry/vue";
import { ContextLines } from "@sentry/integrations";
import Keycloak from "keycloak-js";
import { Store } from "vuex";
import { Router } from "vue-router";

import { version } from "../../package.json";
import { StoreState } from "@/typings/store";
import { Session } from "@/typings";

export default async (app: App, router: Router, kc: Keycloak, store: Store<StoreState>) => {
  const env = import.meta.env.VITE_NODE_ENV;

  let email = "<Couldn't load user>";
  try {
    email = ((await kc.loadUserInfo()) as any).email;
  } catch (err) {
    //
  }

  Sentry.init({
    app,
    dsn: "https://d1564700b211414096e8dfd04f8fd52e@sentry.sweepatic.com/20",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      // @ts-ignore > ts ignore because we can't figure out the exact typing issues with this
      new ContextLines(),
    ],
    // resize observer loop errors are benign and can be ignored alltogether as
    // stated by the author of this functionality https://stackoverflow.com/a/50387233
    ignoreErrors: ["createRouterError", /Redirected/, "cancel", /ResizeObserver loop/],
    environment: env,
    release: `${env}_${version}`,
    beforeSend(event) {
      const tags = event.tags || {};

      const sesh: Session = store.getters["session/session"];

      // in cases where user has no access, sesh will be null
      tags.partner = sesh?.partner?.display_name || "N/A";
      tags.username = email;

      return { ...event, tags };
    },
  });
};
