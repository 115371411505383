import { Getters, Mutations, Actions } from "@/typings/store";
import { FormDialogState } from "@/typings/store/formDialog";

const state: FormDialogState = {
  isLoading: false,
  isDisabled: false,
  isVisible: false,
  isFormValid: true,
  currentStep: 1,
};

const getters: Getters<FormDialogState> = {
  isLoading: (state) => state.isLoading,
  isDisabled: (state) => state.isDisabled,
  isVisible: (state) => state.isVisible,
  isFormValid: (state) => state.isFormValid,
  currentStep: (state) => state.currentStep,
};

const mutations: Mutations<FormDialogState> = {
  setLoading: (state, payload) => (state.isLoading = payload),

  setDisabled: (state, payload) => (state.isDisabled = payload),

  toggleVisible: (state, payload) => (state.isVisible = payload),

  setFormValid: (state, payload) => (state.isFormValid = payload),

  nextStep: (state) => (state.currentStep += 1),

  previousStep: (state) => (state.currentStep -= 1),

  resetCurrentStep: (state) => (state.currentStep = 1),
};

const actions: Actions<FormDialogState> = {
  closeDialog: ({ commit }) => commit("toggleVisible", false),

  openDialog: ({ commit }) => commit("toggleVisible", true),
};

export default { namespaced: true, state, getters, mutations, actions };
