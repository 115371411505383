import Axios from "axios";
import { Store } from "vuex";
import { Router } from "vue-router";

import setupProgressbar from "@/services/progressBar";
import { captureError, captureMessage } from "./sentry.service";
import { healthCheck } from "./session.service";
import { version } from "../../package.json";
import { reportUnexpectedError } from "@/EventBus";
import { StoreState } from "@/typings/store";

const globalCancelToken = Axios.CancelToken.source();

const axios = Axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: { "Content-Type": "application/json" },
});

setupProgressbar(axios);

export const setupAxiosInterceptors = ($store: Store<StoreState>, $router: Router) => {
  let isHealthCheckRequest = false;

  const goToErrorPage = (page: string) => {
    return $router.replace(page).then(() => $store.commit("session/setSessionFetched"));
  };

  const setMaintenance = (payload: boolean) => {
    return $store.commit("session/setMaintenance", { sendRequest: false, payload });
  };

  const handleUnexpectedError = (error: any) => {
    $store.commit("session/setSessionFetched");
    captureError(error);
  };

  // request
  axios.interceptors.request.use(
    (req) => {
      req.headers["X-Version"] = version;
      // attach token to requests
      req.headers.Authorization = `Bearer ${$store.getters["session/token"]}`;

      if (req.url) {
        isHealthCheckRequest = req.url.includes("health_check");

        if (!req.url.includes("system_status")) {
          req.cancelToken = globalCancelToken.token;
        }
      }

      return req;
    },
    (err) => {
      reportUnexpectedError(err);
      Promise.reject(err);
    },
  );

  axios.interceptors.response.use(
    ({ data }) => {
      setMaintenance(false);

      let result = data;

      if (!result) {
        return result;
      }

      if (Object.prototype.hasOwnProperty.call(data, "msg")) {
        result = data.msg;
      } else if (Object.prototype.hasOwnProperty.call(data, "message")) {
        result = data.message;
      }

      return result;
    },
    async (error) => {
      // request cancelled because of maintenance. do nothing
      if (Axios.isCancel(error) || isHealthCheckRequest) {
        return;
      }

      // there was an error connecting to the api
      if (error.toJSON) {
        const msg = error.toJSON().message.toLowerCase();

        if (msg.includes("network error") || msg.includes("empty response")) {
          if (window.location.pathname !== "/no-connection") {
            localStorage.setItem("SW_PREV_PAGE", window.location.pathname);
          }

          try {
            const isOnline = await healthCheck();

            if (isOnline) {
              return;
            }
            // eslint-disable-next-line no-empty
          } catch (err) {}

          return goToErrorPage("/no-connection");
        } else if (msg.includes("aborted")) {
          console.log("request aborted");
          return;
        }
      }

      if (import.meta.env.VITE_NODE_ENV === "local") {
        console.log(error, error.response);
      }

      // un unexpected error ocurred
      if (!error.response) {
        const { url, data } = error.config;

        handleUnexpectedError({ ...error, request: { url, data } });
        return Promise.reject(error);
      }

      const { data, status } = error.response;

      let errorMsg = data;

      if (Object.prototype.hasOwnProperty.call(data, "error")) {
        errorMsg = data.error;
      } else if (Object.prototype.hasOwnProperty.call(data, "reason")) {
        errorMsg = data.reason;
      }

      if (status === 503) {
        setMaintenance(true);

        globalCancelToken.cancel();

        return goToErrorPage("/maintenance");
      } else {
        setMaintenance(false);
      }

      if (status === 401) {
        captureMessage(error);
        return goToErrorPage("/no-access");
      }

      const { url, data: requestData } = error.config;

      // if not 4xx error show error message
      if (String(status).charAt(0) !== "4") {
        handleUnexpectedError({
          error: errorMsg,
          status,
          request: { url, data: requestData },
          response: error.response,
        });
      } else if (status === 403) {
        reportUnexpectedError(errorMsg, "You don't have permissions to perform this action");
      }

      return Promise.reject({
        error: errorMsg,
        status,
        request: { url, data: requestData },
        response: error.response,
        ...(data.errors && { errors: data.errors }),
      });
    },
  );
};

export default axios;
