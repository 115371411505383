/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import "vuetify/styles";

// Composables
import { createVuetify } from "vuetify";
import { vuetifyConfig } from "sweepatic-shared/configs";

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify(vuetifyConfig);
