import axios from "./axios";

import { PartnerUser, ScopeUser } from "@/typings";

export default {
  getPartnerUsers: (partnerName: string) =>
    axios.get<PartnerUser[]>(`/v1/user/partner_users/${partnerName}`),

  getEndUsers: (partnerName: string) => {
    return axios.get(`/v1/user/partner_users/${partnerName}`, { params: { command: "end_users" } });
  },

  createUser: (user: PartnerUser | ScopeUser) => axios.post("/v2/user/users", user),

  deletePartnerUser: (partnerName: string, users: string[]) => {
    return axios.patch(`/v1/partners/${partnerName}`, {
      action: "delete_users",
      users,
      partner: true,
    });
  },

  updateSearchHistory: (value: { type: string; entries: string[] }) => {
    return axios.patch("/v2/user/users", { value, command: "update_search_history" });
  },
};
