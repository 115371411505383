<template>
  <v-container fluid class="mt-4">
    <sw-filter
      placeholder="E.g.: scope: = my_scope, score: = A, account lead:=account.lead@company.com"
      history-key="pi_scopes"
      :total-items="$store.getters['scopes/total']"
      :gui-fields="filterFields"
      :gui-values="filterValues"
    >
      <template v-slot:menu>
        <v-btn
          class="ml-1 rounded"
          color="primary"
          data-cy="btn-create-scope"
          elevation="0"
          height="40px"
          @click="toggleCreateScopeDialog(true)"
        >
          <v-icon size="small" class="mr-1">fa-regular fa-layer-plus</v-icon>
          Create Scope
        </v-btn>

        <sw-scopes-menu />
      </template>
    </sw-filter>

    <sw-scopes-list class="mt-3" @click:open-dialog="openScopeStatusDialog" />

    <sw-scope-create-dialog v-if="isCreateScopeVisible" @click:close="toggleCreateScopeDialog" />

    <sw-scope-status-dialog
      v-if="modifiedScope && isScopeStatusVisible"
      :scope="modifiedScope.scope"
      :status="modifiedScope.disabled ?? false"
      @click:close="closeScopeStatusDialog"
    />
  </v-container>
</template>

<script setup lang="ts">
  import { ref, onMounted, computed } from "vue";

  import SwFilter from "@/components/filter/SwFilter.vue";
  import SwScopesList from "@/components/scopes/SwScopesList.vue";
  import SwScopesMenu from "@/components/scopes/SwScopesMenu.vue";
  import SwScopeCreateDialog from "@/components/scopes/SwScopeCreateDialog.vue";
  import SwScopeStatusDialog from "@/components/scopes/SwScopeStatusDialog.vue";
  import { useStore } from "@/composables/store";
  import type { GuiField, GuiValue } from "@/typings/store/filter";
  import type { Scopes } from "@/typings";

  interface ScopeStatus {
    scope: string;
    disabled: boolean;
  }

  const $store = useStore();

  const isCreateScopeVisible = ref<boolean>(false);
  const isScopeStatusVisible = ref<boolean>(false);
  const modifiedScope = ref<ScopeStatus | null>(null);

  const filterFields = ref<GuiField[]>([
    {
      text: "Score",
      actions: { equals: "selection", greater_than: "selection", lower_than: "selection" },
      stages: 3,
    },
    {
      text: "Scope",
      actions: { equals: "free", not_equals: "free", contains: "free" },
      stages: 3,
    },
    {
      text: "Display Name",
      actions: { equals: "free", not_equals: "free", contains: "free" },
      stages: 3,
    },
    {
      text: "Account Lead",
      actions: { equals: "free", not_equals: "free", contains: "free" },
      stages: 3,
    },
    {
      text: "Account Type",
      actions: { equals: "selection" },
      stages: 3,
    },
    { text: "Created at", actions: { greater_than: "date", lower_than: "date" }, stages: 3 },
    { text: "Start Date", actions: { greater_than: "date", lower_than: "date" }, stages: 3 },
    { text: "End Date", actions: { greater_than: "date", lower_than: "date" }, stages: 3 },
  ]);

  const filterValues = ref<GuiValue>({
    Score: ["A", "B", "C", "D", "E", "F"],
    "Account Type": [
      "demo",
      ...($store.getters["session/hasPremiumScopes"] ? ["premium"] : []),
      ...($store.getters["session/hasProspectScopes"] ? ["prospect"] : []),
      ...($store.getters["session/hasPocScopes"] ? ["PoC"] : []),
    ],
  });

  const showDisabled = computed(() => $store.state.scopes.showDisabled);
  const scopes = computed<Scopes>(() => $store.getters["scopes/scopes"]);

  onMounted(() => {
    $store.dispatch("loading/hideLoading");
    $store.dispatch("scopes/getDynamicQuickFilters");
  });

  const toggleCreateScopeDialog = (val: boolean) => {
    isCreateScopeVisible.value = val;
    $store.commit("formDialog/toggleVisible", val);
  };

  const openScopeStatusDialog = (payload: ScopeStatus) => {
    modifiedScope.value = payload;
    isScopeStatusVisible.value = true;
  };

  const closeScopeStatusDialog = (modified: boolean) => {
    if (modified && !showDisabled.value && !modifiedScope.value?.disabled) {
      const newScopes = scopes.value.filter((scope) => scope.scope !== modifiedScope.value?.scope);
      $store.commit("scopes/setScopes", { scopes: newScopes, total: newScopes.length });
    }

    isScopeStatusVisible.value = false;
    modifiedScope.value = null;
  };
</script>
