import { capitalize } from "sweepatic-shared/utils/formatters";

/**
 * Shortens text by adding ... at the end if size is larger than maxLength
 * @param {string} text - text to shorten
 * @param {int} maxLength - length after which text is shortened. Defaults to 50
 * @returns {string} the shortened text
 */
export const shortenText = (text: string, maxLength: number = 50): string => {
  return text.length <= maxLength ? text : `${text.slice(0, maxLength - 3)}...`;
};

/**
 * Replaces underscores with spaces, capitalizes first letter
 * @param {string} value - value to unnormalize
 * @returns {string} the unnormalized value. some_value > Some value
 */
export const denormalize = (value: string): string => capitalize(value.replaceAll("_", " "));

/**
 * Capitalizes modules and puts all capital EASM
 * @param {string} moduleName - name of the module
 * @returns {string} the capitalized module name. credentials > Credentials, easm > EASM
 */
export const capitalizeModule = (moduleName: string): string => {
  return moduleName == "easm" ? moduleName.toUpperCase() : capitalize(moduleName);
};
