<template>
  <v-menu
    v-model="menuVisible"
    content-class="searches-list"
    location="bottom"
    height="400px"
    width="400px"
    :open-delay="0"
    :close-delay="0"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ props }">
      <v-btn v-if="$vuetify.display.mdAndUp" v-bind="props" color="primary" class="rounded">
        searches
        <v-icon size="x-small" class="ml-1 mb-1">fa-regular fa-chevron-down</v-icon>
      </v-btn>

      <v-btn v-else v-bind="props" variant="plain" size="x-small" class="rounded">
        <v-icon size="small">fa-regular fa-history</v-icon>
        <v-icon size="x-small" class="ml-1">fa-regular fa-chevron-down</v-icon>
      </v-btn>
    </template>

    <v-list density="compact" class="pa-0">
      <template v-if="recentSearches.length">
        <v-list-item
          v-for="item in recentSearches"
          density="compact"
          :key="item"
          :title="item"
          @click="onItemClick(item)"
        />

        <v-divider />

        <v-list-item title="Clear recent searches" density="compact" @click="clearSearches">
          <template v-slot:append>
            <v-icon size="x-small">fa-regular fa-trash</v-icon>
          </template>
        </v-list-item>
      </template>

      <v-list-item v-else title="You don't have any recent searches" density="compact" />
    </v-list>
  </v-menu>
</template>

<script setup lang="ts">
  import { ref, computed } from "vue";

  import { useStore } from "@/composables/store";
  import { EventBus, EVENTS } from "@/EventBus";

  const $store = useStore();

  const menuVisible = ref<boolean>(false);

  const searchHistory = computed(() => $store.getters["session/searchHistory"]);
  const historyKey = computed(() => $store.getters["filter/historyKey"]);
  const recentSearches = computed(() => searchHistory.value?.[historyKey.value] || []);

  const clearSearches = () => {
    $store.dispatch("session/updateSearchHistory", { type: historyKey.value });
  };

  const onItemClick = (term: string) => {
    $store.dispatch("filter/search", term);
    EventBus.emit(EVENTS.FILTER.OUTSIDE, term);
    menuVisible.value = false;
  };
</script>

<style lang="scss" scoped>
  .v-btn {
    height: 40px !important;
    border-radius: 5px;
  }

  .searches-list {
    max-height: 400px;
    width: 400px;

    .v-list-item {
      min-height: 36px;
      border-bottom: 1px solid $gray_60;

      &.type-divider {
        background: rgba($secondary, 0.1);
      }
    }

    input {
      width: 100%;
      outline: 0;
    }
  }
</style>
