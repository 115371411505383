<template>
  <div class="seed-list-wrapper">
    <sw-datatable
      silent
      :headers="headers"
      :data="seeds"
      :hide-footer="true"
      :has-selection="false"
      :clickable="false"
    >
      <!-- make modules togelable -->
      <template v-slot:item.easm="{ item }">
        <sw-switch
          v-model:model-value="item.easm"
          @update:model-value="setDisableSeed(item, 'easm')"
        />
      </template>

      <template v-slot:item.credentials="{ item }">
        <sw-switch
          v-model:model-value="item.credentials"
          @update:model-value="setDisableSeed(item, 'credentials')"
        />
      </template>

      <!-- Remove seed from list -->
      <template v-slot:item.actions="{ item }">
        <v-btn variant="text" size="x-small" @click.stop="removeSeed(item.domain)">
          <v-icon>fa-regular fa-trash</v-icon>
        </v-btn>
      </template>
    </sw-datatable>
  </div>
</template>

<script lang="ts">
  // @ts-nocheck
  import SwDatatable from "sweepatic-shared/components/SwDatatable.vue";
  import SwSwitch from "sweepatic-shared/components/SwSwitch.vue";
  import type { DataTableHeader } from "sweepatic-shared/typings/datatable";
  import { capitalizeModule } from "@/utils/formatters";

  export default {
    name: "sw-scope-create-dialog-seed-list",
    components: { SwDatatable, SwSwitch },
    data() {
      return {
        headers: [
          {
            title: "Domain",
            value: "domain",
            active: true,
            sortable: true,
          },
          {
            title: "EASM",
            value: "easm",
            active: true,
            sortable: true,
          },
        ],
      };
    },
    props: {
      seeds: { type: Array, required: true },
      modules: { type: Array },
    },
    methods: {
      removeSeed(domain: string) {
        this.$emit("click", domain);
      },
      updateColumnHeaders() {
        let easmEnabled = false;
        const moduleHeadersList = this.modules.reduce<DataTableHeader[]>((result, module) => {
          if (module.name === "outscan") {
            return result;
          }

          if (module.name === "easm") {
            easmEnabled = true;
          }

          result.push({
            title: capitalizeModule(module.name),
            value: module.name,
            key: module.name,
            active: true,
            sortable: false,
          });

          return result;
        }, []);

        this.headers = [
          {
            title: "Domain",
            value: "domain",
            active: true,
            sortable: true,
          },
        ];
        if (!easmEnabled) {
          this.headers.push({
            title: "Asset Manager",
            value: "easm",
            active: true,
            sortable: false,
          });
        }
        this.headers = this.headers.concat(moduleHeadersList);
        this.headers.push({
          title: "",
          value: "actions",
          sortable: false,
          class: "fixed-width",
          width: "32px",
        });
      },
      setDisableSeed(seed: string, module: string) {
        if (!seed[module]) {
          seed.modules = seed.modules.filter((mod) => {
            return mod !== module;
          });
          seed[module] = false;
        } else {
          seed.modules.push(module);
          seed[module] = true;
        }
      },
    },
    watch: {
      seeds: {
        handler() {
          this.updateColumnHeaders();
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .seed-list-wrapper {
    max-height: 532px;
    overflow: hidden;
  }
</style>
