<template>
  <v-list-item
    title="Switch input type"
    prepend-icon="fa-regular fa-arrow-right-arrow-left"
    @click="switchInputType"
  />
</template>

<script lang="ts">
  import { defineComponent } from "vue";

  import { EventBus, EVENTS } from "@/EventBus";
  import { mapMutations } from "vuex";

  export default defineComponent({
    name: "sw-switch-input-type-menu-item",
    data() {
      return { isSwitching: false };
    },
    methods: {
      ...mapMutations("filter", ["toggleFilterType", "toggleIsSwitching"]),
      switchInputType() {
        this.toggleIsSwitching(true);

        this.toggleFilterType();
        EventBus.emit(EVENTS.FILTER.FILTER_SWITCH);

        setTimeout(() => this.toggleIsSwitching(false), 250);
      },
    },
  });
</script>
