<template>
  <v-menu open-on-click open-delay="0" content-class="menu-container">
    <template v-slot:activator="{ props }">
      <div
        v-bind="props"
        class="ml-3 cursor-pointer profile-container d-flex align-center"
        :class="{ 'profile-container-active': isMenuActive }"
      >
        <sw-avatar :name="session.name" />
        <i class="ml-2 fa-regular fa-chevron-down" />
      </div>
    </template>

    <v-card @mouseenter="toggleMenu()" @mouseleave="toggleMenu()">
      <v-list density="compact">
        <v-list-item class="user" :title="session.name">
          <template v-slot:prepend>
            <sw-avatar :name="session.name" class="mr-2" />
          </template>
        </v-list-item>

        <!-- @click so it has the nice hover behaviour-->
        <v-list-item @click="() => {}">
          <a
            href="https://www.sweepatic.com/p-5e7490d1c5ed6d177943b1f2/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Tutorials
          </a>
        </v-list-item>

        <v-list-item @click="profile">
          <span>Profile</span>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item @click="logout">
          <span>Log out</span>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
  import { defineComponent } from "vue";
  import { mapGetters } from "vuex";
  import SwAvatar from "sweepatic-shared/components/SwAvatar.vue";

  export default defineComponent({
    name: "sw-profile",
    components: { SwAvatar },
    data() {
      return { isMenuActive: false };
    },
    computed: {
      ...mapGetters("session", ["session"]),
    },
    methods: {
      logout() {
        this.$keycloak.logoutFn?.();
      },
      profile() {
        this.$keycloak.accountManagement?.();
      },
      toggleMenu() {
        this.isMenuActive = !this.isMenuActive;
      },
    },
  });
</script>
