import { InjectionKey } from "vue";
import { createStore, Store } from "vuex";

import scopes from "@/plugins/store/modules/scopes";
import session from "@/plugins/store/modules/session";
import users from "@/plugins/store/modules/users";
import formDialog from "@/plugins/store/modules/formDialog";
import integrations from "@/plugins/store/modules/integrations";
import loading from "@/plugins/store/modules/loading";
import filter from "@/plugins/store/modules/filter";
import logs from "@/plugins/store/modules/logs";
import { StoreState } from "@/typings/store";

// make store state available to composition api (useStore)
// https://vuex.vuejs.org/guide/typescript-support.html#typing-usestore-composition-function
export const storeKey: InjectionKey<Store<StoreState>> = Symbol();

export const store = createStore<StoreState>({
  modules: {
    scopes,
    session,
    users,
    formDialog,
    integrations,
    loading,
    filter,
    logs,
  },
});
