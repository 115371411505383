<template>
  <sw-tooltip v-if="showChart" max-width="400px">
    <div :class="classes">
      {{ score }}
    </div>

    <template v-slot:content>
      <v-container class="bg-white elevation-1 trends fluid">
        <h4>Score trend for the last month.</h4>

        <div class="d-flex">
          <div class="d-flex flex-column justify-space-between score-container">
            <div
              v-for="s in scores"
              :key="s"
              :class="`round-container round-container-small mb-1 score-${s}`"
            >
              {{ s }}
            </div>
          </div>

          <div class="chart-container mt-2">
            <line-chart :data="chartData" :options="chartOptions">
              Something went wrong loading the score trend
            </line-chart>
          </div>
        </div>
      </v-container>
    </template>
  </sw-tooltip>

  <sw-tooltip v-else max-width="315px" :tooltip="tooltip">
    <div :class="classes">
      {{ score }}
    </div>
  </sw-tooltip>
</template>

<script lang="ts">
  // @ts-nocheck
  import { Line as LineChart } from "vue-chartjs";
  import { getLetterFromScore } from "sweepatic-shared/utils";
  import SwTooltip from "sweepatic-shared/components/SwTooltip.vue";
  import { toHumanDate } from "sweepatic-shared/utils";
  import { ChartData, ChartOptions } from "chart.js";

  import { getScoreColor } from "@/utils";

  export default {
    name: "sw-scope-score",
    components: { SwTooltip, LineChart },
    props: {
      total: { type: [String, Number], default: undefined },
      scopeHistory: { type: Array, default: undefined },
    },
    data() {
      return {
        scores: ["A", "B", "C", "D", "E", "F"],
        tooltip: null,
        showChart: false,
        chartOptions: {
          plugins: {
            legend: { display: false },
          },
          scales: {
            y: {
              grid: { display: false },
              beginAtZero: true,
              min: 0,
              max: 1,
              ticks: { display: false, stepSize: 0.1 },
            },
            x: {
              ticks: { display: false },
            },
          },
        } as ChartOptions<"line">,
      };
    },
    computed: {
      score(): string {
        if (typeof this.total === "string") {
          return this.total;
        }

        return getLetterFromScore(this.total);
      },
      classes(): string {
        return `round-container round-container-small score-${this.score}`;
      },
      chartData(): ChartData<"line"> {
        if (!this.scopeHistory || !this.scopeHistory.length) {
          return { datasets: [], labels: [] };
        }

        const labels: string[] = [];
        const data: string[] = [];

        this.scopeHistory.forEach((stat) => {
          labels.push(toHumanDate(stat.created));
          data.push(stat.score);
        });

        return {
          datasets: [
            {
              backgroundColor: getScoreColor(this.score).color,
              data,
              fill: false,
            },
          ],
          labels,
        };
      },
    },
    created() {
      if ([null, undefined].includes(this.total)) {
        this.tooltip = "This scope doesn't have a score yet";
        this.showChart = false;
      } else if (!this.scopeHistory.length) {
        this.tooltip = "No score history data";
        this.showChart = false;
      } else {
        this.tooltip = "";
        this.showChart = true;
      }
    },
  };
</script>

<style lang="scss" scoped>
  .round-container {
    @include text();
    justify-content: center;
    max-height: 100%;
    max-width: 100%;
    height: 32px !important;
    width: 32px !important;
    min-height: 32px !important;
    min-width: 32px !important;
    border-radius: 50%;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    line-height: normal;
    position: relative;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    color: $gray_90;
    background: rgba($gray_80, 0.2);

    &.round-container-small {
      height: 24px !important;
      width: 24px !important;
      min-height: 24px !important;
      min-width: 24px !important;
    }

    &.score-A {
      color: #10b981;
      background: rgba(#10b981, 0.2);
    }

    &.score-B {
      color: #22c55e;
      background: rgba(#22c55e, 0.2);
    }

    &.score-C {
      color: #eab308;
      background: rgba(#eab308, 0.2);
    }

    &.score-D {
      color: #f59e0b;
      background: rgba(#f59e0b, 0.2);
    }

    &.score-E {
      color: #f97316;
      background: rgba(#f97316, 0.2);
    }

    &.score-F {
      color: #ef4444;
      background: rgba(#ef4444, 0.2);
    }
  }

  .chart-container {
    width: 400px;
  }
</style>
