<template>
  <div class="d-flex w-100 mb-4">
    <sw-filter-searches v-if="historyKey" />

    <div
      class="d-flex align-center justify-center filter-wrapper pa-0 w-100"
      :class="{ rotating: isSwitching }"
    >
      <sw-filter-gui v-show="showGui" :fields="guiFields" :values="guiValues" />

      <sw-filter-manual v-show="!showGui" :placeholder="placeholder" />

      <v-btn
        v-show="filter"
        data-cy="clear"
        icon="fa-regular fa-times"
        size="small"
        color="transparent"
        tile
        variant="flat"
        @click="onClear"
      />
    </div>

    <div class="d-flex pa-0 align-center">
      <v-chip label variant="outlined">{{ totalItems }}</v-chip>

      <v-btn
        class="search ml-1 rounded"
        color="primary"
        data-cy="btn-search"
        elevation="0"
        @click="onSearchClick"
      >
        <v-icon size="small">fa-regular fa-search</v-icon>
      </v-btn>

      <div v-if="sorting" class="sort-wrapper ml-1">
        <sw-select
          :model-value="sortField"
          :items="sorting"
          class="rounded"
          @update:model-value="updateSortField"
        />

        <v-btn class="sort rounded ml-1" color="primary" elevation="0" @click="updateSortOrder">
          <v-icon size="small">{{ sortIcon }}</v-icon>
        </v-btn>
      </div>

      <slot name="menu"></slot>
    </div>
  </div>
</template>

<script lang="ts">
  // @ts-nocheck
  import { defineComponent } from "vue";
  import { mapActions, mapGetters, mapMutations } from "vuex";
  import StorageService from "sweepatic-shared/services/storage.service";
  import SwSelect from "sweepatic-shared/components/SwSelect.vue";

  import SwFilterSearches from "@/components/filter/SwFilterSearches.vue";
  import SwFilterGui from "@/components/filter/SwFilterGui.vue";
  import SwFilterManual from "@/components/filter/SwFilterManual.vue";
  import { GuiField, GuiValue } from "@/typings/filter";
  import { EventBus, EVENTS } from "@/EventBus";
  import { SortOrder } from "sweepatic-shared/typings/datatable";
  import { PropType } from "vue";

  interface Data {
    sortOrder: boolean;
    sortField: string;
    tooltip: string;
  }

  interface Props {
    placeholder: string;
    guiFields: GuiField[];
    totalItems: number;
    guiValues?: GuiValue;
    historyKey?: string;
    sorting?: string[];
    initSortField?: string;
    initSortOrder?: boolean;
    paginated?: boolean;
    storageKey?: string;
    storageVersion?: number;
  }

  interface Computed {
    filter: string;
    showGui: boolean;
    sortIcon: string;
  }

  interface Methods {
    search: () => void;
    clear: () => void;
    resetState: () => void;
    onSearchClick: () => void;
    updateSortOrder: () => void;
    updateSortField: (val: string) => void;
    tryStrToBoolean: (val: string) => boolean;
    onClear: () => void;
  }

  interface SortConfigs {
    sortBy: string;
    sortOrder: SortOrder;
    version: number;
  }

  const isClearing = false;

  export default defineComponent({
    name: "sw-filter",
    components: {
      SwFilterGui,
      SwFilterManual,
      SwSelect,
      SwFilterSearches,
    },
    props: {
      placeholder: { type: String, required: true },
      guiFields: { type: Array, required: true },
      guiValues: { type: Object, default: () => ({}) },
      historyKey: { type: String, default: null },
      sorting: {
        type: Array,
        default: null,
        validator: (val: any) => !!val?.length,
      },
      storageKey: { type: String, default: null },
      storageVersion: { type: Number, default: null },
      initSortField: { type: String, default: null },
      initSortOrder: { type: String as PropType<SortOrder>, default: "asc" },
      paginated: { type: Boolean, default: true },
      totalItems: { type: Number, default: 0 },
    },
    data() {
      return {
        tooltip: "Switch to free text input",
        sortField: this.initSortField,
        sortOrder: this.initSortOrder,
      };
    },
    computed: {
      ...mapGetters("filter", ["filter", "showGui", "isSwitching"]),
      sortIcon() {
        return this.sortOrder === "desc"
          ? "fa-regular fa-sort-amount-down"
          : "fa-regular fa-sort-amount-up";
      },
    },
    watch: {
      "$route.query": {
        handler(curr, old) {
          // filter changes
          // should be true when we're in a page and then click on the menu to
          // go to the same page (e.g.: from /tracker click on trakcer in menu)
          // should reset filter
          if (curr.filter !== old.filter && !!this.filter && !curr && !isClearing) {
            this.setFilter("");
          }

          // there are no sorting options enabled, no need to do anything else
          if (!this.sorting) {
            return;
          }

          // page that has sorting options & datatable, and there was a change in the sorting
          // from the datatable, we need to update the sorting in the filter
          if (curr.sortBy !== old.sortBy) {
            this.sortField = curr.sortBy;
          }

          if (curr.sortOrder !== old.sortOrder) {
            this.sortOrder = curr.sortOrder;
          }
        },
      },
    },

    beforeMount() {
      const { filter, sortOrder, sortBy, page } = this.$route.query;

      this.resetState({
        historyKey: this.historyKey,
        filter: filter || "",
        isPaginated: this.paginated,
      });

      if (!this.sorting) {
        return;
      }

      let newQuery: {
        sortBy?: string;
        sortOrder?: boolean;
        page?: number;
      } = {};

      if (!sortBy) {
        // eslint-disable-next-line
        let sorting: Omit<SortConfigs, "version"> = {
          sortBy: this.initSortField,
          sortOrder: this.initSortOrder ?? false,
        };

        if (this.storageKey) {
          const storage = StorageService.get<SortConfigs>(this.storageKey);

          if (storage && storage.version && this.storageVersion === storage.version) {
            sorting = { sortBy: storage.sortBy, sortOrder: storage.sortOrder };
            this.sortField = storage.sortBy;
            this.sortOrder = storage.sortOrder;
          }
        }

        newQuery = sorting;
      } else {
        this.sortField = sortBy;
        this.sortOrder = this.tryStrToBoolean(sortOrder);
      }

      if (isNaN(page)) {
        newQuery.page = 1;
      }

      this.$router.updateQuery(newQuery);
    },
    mounted() {
      // listen for browser back button click to update filter accordingly
      window.onpopstate = () => {
        this.setFilter(this.$route.query.filter);
        EventBus.emit(EVENTS.FILTER.OUTSIDE, this.$route.query.filter);
      };

      if (this.sorting?.length && !this.initSortField) {
        throw new Error(
          `[${this.$parent?.$.type.name} > sw-filter]: initSortField needs to be specified if there's sorting`,
        );
      }

      if (
        (this.storageKey && !this.sorting?.length) ||
        (!this.storageKey && this.sorting?.length)
      ) {
        throw new Error(
          `[${this.$parent?.$.type.name} > sw-filter]: sorting and storageKey need to be both defined if one is`,
        );
      }

      if (this.storageKey && !this.storageVersion) {
        throw new Error(
          `[${this.$parent?.$.type.name} > sw-filter]: if storageKey exists, storageVersion is also needed`,
        );
      }

      if (this.initSortField && !this.$route.query.sortBy) {
        this.$router.updateQuery({ sortBy: this.initSortField, sortOrder: this.initSortOrder });
      }
    },
    beforeUnmount() {
      // force onpopstate into a no-op on destroy so nothing
      // bleeds into other views same goal as EventBus.$off
      window.onpopstate = () => {};
    },

    methods: {
      ...mapActions("filter", ["search", "clear"]),
      ...mapMutations("filter", ["resetState", "setFilter"]),
      onSearchClick() {
        this.search();
        EventBus.emit(EVENTS.FILTER.SEARCH_CLICK);
      },
      updateSortOrder() {
        this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
        this.$router.updateQuery({ sortOrder: this.sortOrder });

        if (this.storageKey) {
          StorageService.save(this.storageKey, {
            sortBy: this.sortField,
            sortOrder: this.sortOrder,
            version: this.storageVersion,
          });
        }
      },
      updateSortField(field) {
        this.sortField = field;
        this.$router.updateQuery({ sortBy: field });

        if (this.storageKey) {
          StorageService.save(this.storageKey, {
            sortBy: field,
            sortOrder: this.sortOrder,
            version: this.storageVersion,
          });
        }
      },
      tryStrToBoolean(str) {
        try {
          return JSON.parse(str);
        } catch (err) {
          return str;
        }
      },
      onClear() {
        this.isClearing = true;

        this.clear();

        this.$nextTick(() => (this.isClearing = false));
      },
    },
  });
</script>

<style lang="scss" scoped>
  @keyframes spin {
    100% {
      transform: rotateX(180deg);
    }
  }

  .rotating {
    animation: spin 250ms linear;

    i {
      animation: spin 250ms linear;
    }

    :deep(input) {
      animation: spin 250ms linear;
    }
  }

  :deep(ul) {
    list-style: none;
    display: flex;
    padding-left: 0;
    overflow-x: auto;
    overflow-y: hidden;
    height: 36px;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    input {
      height: 36px;
      width: 100%;
      cursor: pointer;
      outline: none;
      min-width: 100px;
      background: rgba(255, 255, 255, 0.5);
    }
  }

  .filter-wrapper {
    border-bottom-right-radius: 6px !important; // needs to be important or top borders wont have radius
    border-top-right-radius: 6px !important; // needs to be important or top borders wont have radius
    border: 1px solid $gray_60;
    transition: 0.2s;

    > div {
      width: 100%;
      margin-right: auto;
    }

    &:hover {
      .switch,
      .sort,
      .sort-wrapper,
      .search {
        border-left-color: $gray_70;
        transition: 0.2s;
        color: $gray_90 !important;
      }

      color: $gray_90;
      border-color: $gray_70;
      transition: 0.2s;

      i {
        color: $gray_90 !important;
        transition: 0.2s;
      }

      .sw-select {
        :deep(.v-field__input) {
          transition: 0.2s;
          color: $gray_90 !important;
        }

        :deep(.v-field__append-inner) {
          i {
            transition: 0.2s;
            color: $gray_90 !important;
          }
        }
      }
    }

    &:focus-within,
    &.input-active {
      border-color: rgba($secondary, 0.5);
      color: $secondary !important;
      transition: 0.2s;

      .switch,
      .sort,
      .sort-wrapper,
      .search {
        border-left-color: rgba($secondary, 0.5);
      }

      :deep(input) {
        color: $secondary !important;
      }

      i {
        transition: 0.2s;
        color: $secondary !important;
      }

      .sw-select {
        :deep(.v-field__input) {
          transition: 0.2s;
          color: $secondary !important;
        }

        :deep(.v-field__append-inner) {
          i {
            transition: 0.2s;
            color: $secondary !important;
          }
        }
      }
    }
  }

  button {
    height: 40px !important;
    border-radius: 5px;

    &.sort,
    &.search {
      transition: 0.2s;
    }

    i {
      opacity: 1;
      transition: 0.2s;
    }
  }

  .sort {
    width: 36px;
  }

  .sort-wrapper {
    display: flex !important;
    align-items: center;
    height: 40px;

    .sw-select {
      height: 40px;
      width: 150px;
      background: $primary;
      color: white;
      padding: 0;

      :deep(.v-field__append-inner) {
        margin-top: 0 !important;
        margin-bottom: 2px !important;
        margin-right: 12px;

        i {
          color: white;
          transition: 0.2s;
        }
      }

      // margin: 0;
      // padding: 0;
      // border-radius: 5px;

      // :deep(.v-input__control) {
      //   padding: 0 0 0 2px;
      //   margin: 0;
      //   border: 0;
      //   align-self: center;

      //   [role="button"] {
      //     margin: 0;
      //     padding: 0 4px;
      //     height: 100%;
      //     border: 0;
      //   }

      //   .v-field__input {
      //     margin-top: 2px;
      //     color: white;
      //     transition: 0.2s;
      //   }
      // }

      // :deep(.v-field__append-inner) {
      //   margin-top: 0 !important;
      //   margin-bottom: 2px !important;
      //   margin-right: 12px;

      //   i {
      //     color: white;
      //     transition: 0.2s;
      //   }
      // }
    }
  }

  .v-chip {
    height: 42px !important;
    border-color: $gray_60 !important;

    :deep(.v-chip__content) {
      font-size: 16px !important;
    }
  }
</style>
