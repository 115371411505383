<template>
  <sw-asset-menu>
    <template v-slot:actions>
      <sw-switch-input-type-menu-item />
    </template>

    <template v-slot:settings>
      <sw-toggle-menu-item
        tooltip="Turn on to include disabled scopes"
        label="Show disabled"
        :value="showDisabled"
        @click:toggle="toggleDisabled"
      />
    </template>
  </sw-asset-menu>
</template>
<script setup lang="ts">
  import { computed } from "vue";

  import SwAssetMenu from "@/components/menu/SwAssetMenu.vue";
  import SwSwitchInputTypeMenuItem from "@/components/menu/SwSwitchInputTypeMenuItem.vue";
  import SwToggleMenuItem from "@/components/menu/SwToggleMenuItem.vue";

  import { useStore } from "@/composables/store";

  const $store = useStore();

  const showDisabled = computed(() => $store.state.scopes.showDisabled);

  const toggleDisabled = (payload: boolean) => {
    $store.dispatch("scopes/setShowDisabled", payload);
  };
</script>
