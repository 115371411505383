import OpenReplay from "@openreplay/tracker";

export default () => {
  const { VITE_NODE_ENV, VITE_OPEN_REPLAY_KEY } = import.meta.env;

  if (VITE_NODE_ENV === "local") {
    return;
  }

  return new OpenReplay({ projectKey: VITE_OPEN_REPLAY_KEY }).start();
};
