import { Store } from "vuex";
import { Router } from "vue-router";
import { setupRouter } from "sweepatic-shared/configs";

import Scopes from "@/views/Scopes.vue";
import { StoreState } from "@/typings";

let router: Router;

export default (store: Store<StoreState>): Router => {
  const instance = setupRouter([
    {
      path: "/",
      name: "Scopes",
      meta: { title: "Scopes" },
      component: Scopes,
    },
    {
      path: "/users",
      name: "users",
      meta: { title: "Users" },
      component: () => import("@/views/Users.vue"),
    },
    {
      path: "/end-users",
      name: "end-users",
      meta: { title: "End Users" },
      component: () => import("@/views/EndUsers.vue"),
    },
    {
      path: "/integrations",
      name: "integrations",
      meta: { title: "Integrations" },
      component: () => import("@/views/Integrations.vue"),
    },
    {
      path: "/audit-logs",
      name: "audit-logs",
      meta: { title: "Audit Logs" },
      component: () => import("@/views/AuditLogs.vue"),
    },
    {
      path: "/scopes/:scope",
      name: "scope-editor",
      meta: { title: "Edit Scope" },
      component: () => import("@/components/scopes/SwScopeEditor.vue"),
    },
    {
      path: "/no-connection",
      name: "no-connection",
      component: () => import("@/views/NoConnection.vue"),
    },
    {
      path: "/no-access",
      name: "no-access",
      component: () => import("@/views/NoAccess.vue"),
    },
    {
      path: "/:catchAll(.*)",
      redirect: "/",
    },
  ]);

  instance.beforeEach(async (to, from, next) => {
    document.title = `Sweepatic | Partner Interface ${to.meta.title ? `- ${to.meta.title}` : ""}`;

    if (to.path !== from.path) {
      store.dispatch("loading/showLoading");
    }

    return next();
  });

  router = instance;

  return instance;
};

export { router };
