<template>
  <div class="loading-wrapper">
    <div class="loading-text">LOADING</div>
    <div class="loading-content"></div>
  </div>
</template>

<style lang="scss" scoped>
  .loading-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    background: $gray_50;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;

    .loading-text {
      position: absolute;
      font-size: 20px;
      color: $gray_100;
    }

    .loading-content {
      width: 170px;
      height: 170px;
      border: 3px solid transparent;
      border-top-color: $primary;
      border-bottom-color: $primary;
      border-radius: 50%;
      animation: loader 2s linear infinite;
    }
  }

  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
</style>
