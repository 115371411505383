import UsersService from "@/services/users.service";
import { Getters, Mutations, Actions } from "@/typings/store";
import { PartnerUsersState } from "@/typings/store/users";

const state: PartnerUsersState = {
  partnerUsers: [],
  endUsers: [],
};

const getters: Getters<PartnerUsersState> = {
  partnerUsers: (state) => state.partnerUsers,
  partnerUsersList: (state) => {
    return state.partnerUsers.map((partner) => {
      return { title: partner.name, value: partner.email };
    });
  },
  endUsers: (state) => state.endUsers,
};

const mutations: Mutations<PartnerUsersState> = {
  setPartnerUsers(state, payload) {
    state.partnerUsers = payload;
  },

  deletePartnerUser(state, usersToBeDeleted) {
    state.partnerUsers = state.partnerUsers.filter(
      (user) => !usersToBeDeleted.includes(user.email),
    );
  },

  addPartnerUser(state, partnerUser) {
    state.partnerUsers.push(partnerUser);
  },

  setEndUsers(state, endUsers) {
    state.endUsers = endUsers;
  },
};

const actions: Actions<PartnerUsersState> = {
  async getPartnerUsers({ commit, state }, partnerName) {
    if (!state.partnerUsers.length) {
      commit("setPartnerUsers", await UsersService.getPartnerUsers(partnerName));
    }
  },

  async deletePartnerUser({ commit }, payload) {
    await UsersService.deletePartnerUser(payload.partner, payload.usersToBeDeleted);

    commit("deletePartnerUser", payload.usersToBeDeleted);
  },

  async createPartnerUser({ commit }, payload) {
    await UsersService.createUser(payload);

    payload.name = `${payload.firstName} ${payload.lastName}`;
    commit("addPartnerUser", payload);
  },

  async getEndUsers({ commit }, partne_name) {
    commit("setEndUsers", await UsersService.getEndUsers(partne_name));
  },
};

export default { namespaced: true, state, getters, mutations, actions };
