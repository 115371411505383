<template>
  <v-app-bar color="primary" elevation="0" class="px-3" density="compact">
    <img
      v-if="smAndUp"
      src="/assets/img/logo-white.png"
      alt="Sweepatic Security Logo"
      @click="goToHome"
    />

    <sw-navbar-item v-for="(item, idx) of menuItems" :key="idx" :item="item" />

    <template v-slot:append>
      <sw-partner-selector />

      <sw-profile />
    </template>
  </v-app-bar>
</template>

<script setup lang="ts">
  import { useDisplay } from "vuetify";
  import { ref } from "vue";
  import { useRouter } from "vue-router";

  import SwNavbarItem from "./SwNavbarItem.vue";
  import SwProfile from "./SwProfile.vue";
  import SwPartnerSelector from "./SwPartnerSelector.vue";
  import { MenuItem } from "@/typings/nav";

  const { smAndUp } = useDisplay();
  const $router = useRouter();

  const menuItems = ref<MenuItem[]>([
    { to: "/", icon: "fa-album", label: "Scopes" },
    { to: "/users", icon: "fa-users", label: "Partner Users" },
    { to: "/end-users", icon: "fa-users", label: "End Users" },
    { to: "/integrations", icon: "fa-plug", label: "Integrations" },
    { to: "/audit-logs", icon: "fa-clipboard-list", label: "Audit Logs" },
  ]);

  const goToHome = () => $router.push("/");
</script>

<style lang="scss" scoped>
  .v-toolbar__content {
    padding: 8px 16px;
  }

  img {
    width: 128px;
    margin-right: 16px;
    cursor: pointer;
  }

  span {
    color: #fff;
    border-right: 1px solid #fff;
    padding: 10px;
  }
</style>
