import { GenericObject, Integration } from "@/typings";
import axios from "./axios";

export const getIntegrations = (partnerName: string) => axios.get(`/v1/partners/${partnerName}/integrations`);

export const addIntegration = (partnerName: string, integration: Integration) => {
  return axios.post<Integration>(`/v1/partners/${partnerName}/integrations`, integration);
};

export const updateIntegration = (partnerName: string, integration: Integration) => {
  return axios.patch(`/v1/partners/${partnerName}/integrations`, integration);
};

export const removeIntegration = ({ id, partnerName }: GenericObject) => {
  return axios.delete(`/v1/partners/${partnerName}/integrations`, { params: { id } });
};

export const testIntegration = (integration: Integration) => {
  return axios.post("/v2/integrations/test", integration);
};

export default { getIntegrations, addIntegration, updateIntegration, removeIntegration, testIntegration };
