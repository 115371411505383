<template>
  <v-menu v-model="isVisible" open-on-click :close-on-content-click="false" @click.stop.prevent>
    <template v-slot:activator="{ props }">
      <i class="fa-regular fa-filter fa-sm mr-1" v-bind="props" />
    </template>

    <v-list density="compact">
      <v-list-item
        v-for="item in items"
        :key="item.value"
        :title="item.value"
        density="compact"
        @click="onRowClick(item.value)"
      >
        <template v-slot:prepend>
          <v-checkbox
            v-model:model-value="checkModel[item.value]"
            hide-details
            density="compact"
            class="mr-1"
          />
        </template>
      </v-list-item>

      <v-divider class="mt-2 mb-1" />

      <v-list-item density="compact" title="Apply" @click="onFilter" />
    </v-list>
  </v-menu>
</template>

<script lang="ts">
  // @ts-nocheck
  import { defineComponent } from "vue";
  import { mapActions, mapGetters } from "vuex";
  import { QuickFilter } from "@/typings";
  import { EventBus, EVENTS } from "@/EventBus";

  interface Data {
    checkModel: {
      [key: string]: boolean;
    };
    isVisible: boolean;
  }

  interface Methods {
    onRowClick(value: string): void;
    onFilter(): void;
    resetToDefault(): void;
  }

  interface Computed {
    quickFilters: QuickFilter[];
  }

  interface Item {
    value: string;
    default: boolean;
  }

  interface Props {
    field: string;
    items: Item[];
  }

  export default defineComponent({
    name: "sw-quick-filter-menu",
    props: {
      field: { type: String, required: true },
      items: { type: Array, required: true },
    },
    data() {
      return { checkModel: {}, isVisible: false };
    },
    computed: {
      ...mapGetters("filter", ["quickFilters"]),
    },
    mounted() {
      // if filtering exist in url, we choose the selected boxes based on that instead of default values
      const hasEnabledFilters = Object.keys(this.quickFilters).length;
      // if one of the fields has all items unselected it won't exist in quickFilters, so fallback to empty array
      const enabledFilters = this.quickFilters[this.field] || [];

      this.checkModel = this.items.reduce((result: string[], item: Item) => {
        result[item.value] = hasEnabledFilters ? enabledFilters.includes(item.value) : item.default;

        return result;
      }, {});

      EventBus.on(EVENTS.FILTER.QUICK_FILTER_RESET, this.resetToDefault);
    },
    beforeUnmount() {
      EventBus.off(EVENTS.FILTER.QUICK_FILTER_RESET, this.resetToDefault);
    },
    methods: {
      ...mapActions("filter", ["quickFilterChange"]),
      onRowClick(value: string) {
        this.checkModel[value] = !this.checkModel[value];
      },
      onFilter() {
        this.quickFilterChange({
          field: this.field,
          filters: Object.entries(this.checkModel).reduce((result, [value, selected]) => {
            if (selected) {
              result.push(value);
            }

            return result;
          }, []),
        });

        this.isVisible = false;
      },
      resetToDefault() {
        this.checkModel = this.items.reduce((result: string[], item: Item) => {
          result[item.value] = item.default;

          return result;
        }, {});
      },
    },
  });
</script>

<style lang="scss" scoped>
  .fa-filter {
    color: #6b7280;
  }

  :deep(.v-list-item) {
    min-height: 20px;
    padding: 0 4px;
  }
</style>
