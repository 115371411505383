import { deepCopy } from "sweepatic-shared/utils";
import { Getters, Mutations, Actions } from "@/typings/store";
import { IntegrationState } from "@/typings/store/integration";
import IntegrationService from "@/services/integrations.service";
import { Integration } from "@/typings";

const defaultIntegration: IntegrationState = {
  name: "",
  type: "",
  connection: {},
  configuration: {},
};

const state: IntegrationState = {
  integrations: [],
  // parse and stringify to prevent nested details from keeping its pointer
  currentIntegration: deepCopy(defaultIntegration),
  isEditMode: false,
};

const getters: Getters<IntegrationState> = {
  integrations: (state) => state.integrations,

  currentIntegration: (state) => state.currentIntegration,

  isEditMode: (state) => state.isEditMode,
};

const mutations: Mutations<IntegrationState> = {
  setIntegrations: (state, data) => (state.integrations = data),

  setCurrentIntegration: (state, payload) => {
    // use default as new object to avoid unintended mutations
    // parse and stringify to prevent nested details from keeping its pointer
    state.currentIntegration = payload || deepCopy(defaultIntegration);
  },

  setEditMode: (state, payload) => (state.isEditMode = payload),

  initIntegrationDialog: (state) => (state.integrationDialogRendered = true),

  toggleIntegrationCreated: (state, payload) => (state.integrationCreated = payload),
};

const actions: Actions<IntegrationState> = {
  async getIntegrations({ commit, state }, partnerName) {
    if (state.integrations.length) {
      return state.integrations;
    }

    const data = await IntegrationService.getIntegrations(partnerName);
    commit("setIntegrations", data);
    return data;
  },

  // payload is specified when adding user email as integration
  // from general tab (SwDialogTabGeneral/addIntegrationEmail())
  async addIntegration({ commit, state }, partnerName) {
    const data = await IntegrationService.addIntegration(partnerName, state.currentIntegration);
    commit("setIntegrations", [...state.integrations, data]);
    commit("toggleIntegrationCreated", true);
    return data;
  },

  async updateIntegration({ commit, state }, partnerName) {
    const data = await IntegrationService.updateIntegration(partnerName, state.currentIntegration);

    const result = state.integrations.map((t: Integration) => (t._id === data._id ? data : t));

    commit("setIntegrations", result);
    return result;
  },

  async removeIntegration({ commit, state }, payload) {
    const data = await IntegrationService.removeIntegration(payload);
    commit(
      "setIntegrations",
      state.integrations.filter((item: Integration) => item._id !== payload.id),
    );
    return data;
  },

  updateCurrentIntegration({ commit }, payload) {
    commit("setCurrentIntegration", payload);
  },
};

export default { namespaced: true, state, getters, mutations, actions };
