import { Getters, Mutations, Actions } from "@/typings/store";
import { SessionState } from "@/typings/store/session";
import SessionService from "@/services/session.service";
import UsersService from "@/services/users.service";
import { SearchHistoryType } from "@/typings";

interface UpdateSearchHistoryAction {
  entry: string;
  type: SearchHistoryType;
}

const state: SessionState = {
  session: null,
  isSessionFetched: false,
  maintenance: false,
  token: null,
  searchHistory: null,
};

const getters: Getters<SessionState> = {
  session: (state) => state.session,
  isAdmin: (state) => state.session?.account_type === "admin",
  partnerScopes: (state) => state.session?.partner.scopes,
  isSessionFetched: (state) => state.isSessionFetched,
  itemsPerPagination: (state) => state.session?.itemsPerPagination || 100,
  isPartnerHeadCountBased: (state) =>
    state.session?.partner.license?.contract_type === "head_count_based",
  partnerContractType: (state) => state.session?.partner.license?.contract_type,
  partner: (state) => state.session?.partner.name,
  scopeMetadata: (state) => state.session?.partner?.scope_metadata || [],
  hasProspectScopes: (state) => state.session?.partner?.modules?.prospect_scopes,
  hasPocScopes: (state) => state.session?.partner?.modules?.poc_scopes,
  hasPremiumScopes: (state) => state.session?.partner?.modules?.premium_scopes !== false,
  scopeTransfer: (state) => state.session?.partner?.modules?.scope_transfer,
  editableColumns: (state) =>
    (state.session?.partner?.scope_metadata || []).filter((data) => data.editable),
  token: (state) => state.token,
  searchHistory: (state) => state.searchHistory,
};

const mutations: Mutations<SessionState> = {
  setSession: (state, result) => {
    if (result.search_history) {
      state.searchHistory = result.search_history;

      delete result.search_history;
    }
    state.session = result;
  },

  setSessionFetched: (state) => (state.isSessionFetched = true),

  setMaintenance: (state, payload: boolean) => (state.maintenance = payload),

  setToken: (state, payload) => (state.token = payload),

  updateSearchHistory: (state, { entries, type }) => {
    state.searchHistory = {
      ...(state.searchHistory || {}),
      [type]: entries,
    };
  },
};

const actions: Actions<SessionState> = {
  async getSession({ commit }) {
    const session = await SessionService.getSession("details");

    commit("setSession", session);
    commit("setSessionFetched");

    return session;
  },

  async updateSearchHistory({ commit, state }, { entry, type }: UpdateSearchHistoryAction) {
    const searchHistory: string[] = state.searchHistory?.[type] || [];

    // if it already exists, we won't readd
    if (searchHistory.includes(entry)) {
      commit("updateSearchHistory", {
        entries: [entry, ...searchHistory.filter((item) => item !== entry)],
        type,
      });
      return;
    }

    const entries = entry ? [entry, ...searchHistory].slice(0, 20) : [];
    const payload = { entries, type };

    await UsersService.updateSearchHistory(payload);

    commit("updateSearchHistory", payload);
  },
};

export default { namespaced: true, state, getters, mutations, actions };
