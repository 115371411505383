<template>
  <!-- 
    TODO: move to shared 
    todo: fix error icon not showing up
    todo: find out why it's slow to open even tho there's little data
  -->
  <v-autocomplete
    density="compact"
    variant="outlined"
    :height="height"
    :hide-selected="hideSelected"
    :hide-details="hideDetails"
    :placeholder="placeholder"
    :model-value="localValue"
    :items="items"
    :error-messages="errorMessages"
    :multiple="multiple"
    :chips="chips"
    :readonly="readonly"
    :label="label"
    @update:model-value="onInputChange"
  >
    <template v-if="chips" v-slot:selection="{ item }">
      <v-chip size="small" close class="my-1" @click:close="removeSelection(item.value)">
        {{ item.text }}
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
  // @ts-nocheck
  import { defineComponent } from "vue";

  interface Data {
    localValue: string | string[];
  }

  interface Methods {
    onInputChange: (val: string) => void;
    removeSelection: (value: string) => void;
  }

  interface Props {
    items: Array<{ text: string; value: string }>;
    value: string | Array<string>;
    hideSelected?: boolean;
    hideDetails?: boolean;
    placeholder?: string;
    height?: number | string;
    errorMessages?: string;
    multiple?: boolean;
    chips?: boolean;
    readonly?: boolean;
    label?: string;
  }
  export default defineComponent<Data, Methods, {}, Props>({
    name: "sw-auto-complete",
    props: {
      items: { type: Array, required: true },
      height: { type: [Number, String], default: "auto" },
      hideSelected: { type: Boolean, default: true },
      hideDetails: { type: Boolean, default: true },
      placeholder: { type: String, default: "" },
      value: { type: [String, Array], default: "" },
      errorMessages: { type: String, default: null },
      multiple: { type: Boolean, default: false },
      chips: { type: Boolean, default: false },
      readonly: { type: Boolean, default: false },
      label: { type: String, default: "" },
    },
    data() {
      return {
        localValue: "",
      };
    },
    watch: {
      value: {
        handler(newVal) {
          if (this.localValue !== newVal) {
            this.localValue = newVal;
          }
        },
        immediate: true,
      },
    },
    methods: {
      onInputChange(val) {
        this.localValue = val;
        this.$emit("input", val);
      },
      removeSelection(value) {
        this.localValue = this.localValue.filter((val) => val !== value);
        this.$emit("input", this.localValue);
      },
    },
  });
</script>

<style lang="scss" scoped>
  :deep(.v-auto_complete) {
    width: 840px;
  }
  :deep(.v-input__control) {
    border-radius: 6px !important;
    border: none;
    border-color: $gray_70;
    font-size: 14px;
    line-height: 16px;
    transition: 0.2s;

    cursor: pointer;

    fieldset {
      border: 2px solid #e5e7eb;
    }

    &:hover {
      fieldset {
        border-color: $gray_70;
        transition: 0.2s;
      }
    }

    .v-input__slot {
      padding: 8px 12px;
      width: auto;

      &:before,
      &:after {
        border: none !important;
      }
      .v-select__slot {
        display: flex;
        justify-content: space-between;

        .v-label {
          font-size: 18px;
          z-index: 100;
        }

        .v-select__selections {
          padding: 10px 5px;
        }
        .v-input__append-inner {
          align-self: center;
          margin: 0;
          justify-self: end;

          .v-input__icon {
            &.v-input__icon--append {
              min-width: 16px;
              width: 16px;
              height: 16px;
              color: #6b7280;
              cursor: pointer;

              i {
                margin-top: 0 !important;
                font-size: 14px !important;
              }
            }
          }
        }

        input {
          border: none !important;
          flex: none;
          display: block;
          width: 100px;
          min-width: 0;
          max-width: initial;
          padding: 0;
          min-height: 0;
          font-size: 14px;
          line-height: 16px;

          &[type="text"] {
            width: 250px;
          }

          &:hover {
            border: none !important;
          }
        }
      }
    }
  }
</style>
