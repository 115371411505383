import { captureMessage as cm, captureException, withScope } from "@sentry/vue";

import { GenericObject } from "@/typings";

export const captureMessage = cm;

export const captureError = (error: GenericObject) => {
  withScope((sentryScope) => {
    sentryScope.setTag("statusCode", error.status || "unknown");

    if (error.request) {
      sentryScope.setTag("request", JSON.stringify(error.request));
    }

    captureException(JSON.stringify(error.error || error));
  });
};

export default { captureError, captureMessage };
