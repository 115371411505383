<template>
  <v-snackbar
    v-model:model-value="isVisible"
    rounded
    :bottom="true"
    :color="color"
    :timeout="timeout"
  >
    <span v-html="msg" />

    <template v-slot:actions>
      <v-btn variant="text" @click="isVisible = false"> Close </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
  import { EVENTS, EventBus } from "@/EventBus";

  export default {
    name: "sw-snackbar",
    data() {
      return { isVisible: false, msg: "", color: "", timeout: 5000 };
    },
    mounted() {
      EventBus.on(EVENTS.TOAST.ERROR, this.triggerError);
      EventBus.on(EVENTS.TOAST.SUCCESS, this.triggerSuccess);
    },
    beforeUnmount() {
      EventBus.off(EVENTS.TOAST.ERROR, this.triggerError);
      EventBus.off(EVENTS.TOAST.SUCCESS, this.triggerSuccess);
    },
    methods: {
      triggerSuccess(payload: string) {
        this.triggerSnack(payload, "#3e8255");
      },
      triggerError(payload: string) {
        this.triggerSnack(payload, "#c72e20");
      },
      triggerSnack(msg: string, color: string, timeout = 5000) {
        this.msg = msg;
        this.isVisible = true;
        this.color = color;
        this.timeout = timeout;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .v-snackbar {
    z-index: 99999 !important;

    :deep(.v-btn__content),
    span {
      color: #fff !important;
    }
  }
</style>
