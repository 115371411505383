<template>
  <v-dialog :model-value="open" max-width="600" @click:outside="close">
    <v-card v-if="modules && selectedModuleType">
      <v-card-text>
        <template v-if="isLicense && canUpgrade">
          <div class="my-4">
            A premium account is a full sweepatic paid scope. <br /><br />
            This means that creating this scope will add the cost of it to your next invoice.

            <template v-if="targetPartner">
              <br />
              This scope will be transferred to {{ targetPartner }} after premium upgrade.
            </template>
          </div>

          <sw-select
            v-model:model-value="licenseDuration"
            placeholder="Choose license duration e.g:'1 year'"
            label="License Duration"
            :items="licenseDurations"
            :error="errors.licenseDuration"
          />

          <sw-input
            v-if="isPartnerHeadCountBased"
            v-model:model-value="headCount"
            label="Head Count"
            placeholder="Number of employees client has"
            class="w-100 mt-4"
            type="number"
            :error="errors.headCount"
          />
        </template>

        <template
          v-else-if="(!isActivate && !isEdit) || !EDITABLE_MODULES.includes(selectedModuleType)"
        >
          Are you sure you want to

          <span v-if="isLicense">
            {{ modules[selectedModuleType].licensed ? "remove the license of" : "license" }}
          </span>

          <span v-else>
            {{ modules[selectedModuleType].enabled ? "disable" : "enable" }}
          </span>

          {{ selectedModuleType.toUpperCase() }}?
        </template>

        <template v-if="isActivate || isEdit">
          <div v-if="selectedModuleType === ModuleType.credentials">
            <sw-select
              v-model:model-value="selectedBand"
              class="mt-2"
              item-title="name"
              item-value="key"
              placeholder="Select band"
              label="Select band"
              :return-object="false"
              :items="Object.values(bands)"
              :error-messages="errors.band"
            />

            <v-table v-if="selectedBand">
              <thead>
                <tr>
                  <th>DESCRIPTION</th>
                  <th>HIGH LIMIT</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(value, name) in bands[selectedBand]" :key="name">
                  <tr v-if="!['name', 'key'].includes(name)">
                    <td>{{ name }}</td>
                    <td>{{ value ?? Infinity }}</td>
                  </tr>
                </template>
              </tbody>
            </v-table>
          </div>

          <!-- License dialog for outscan -->
          <div v-else-if="selectedModuleType === ModuleType.outscan">
            <h3>Insert credentials to license outscan</h3>
            <div class="mt-5">
              <sw-input
                v-model:model-value="outscanConfig.credentials.username"
                class="my-2 mx-1"
                label="Username"
                placeholder="Username"
                :error="errors.outscanUsername"
              />
            </div>

            <div>
              <sw-input
                v-model:model-value="outscanConfig.credentials.password"
                class="my-2 mx-1"
                label="Set password"
                type="password"
                :error="errors.outscanPassword"
              />
            </div>

            <span v-if="errors.invalidCredentials" class="text-red">
              {{ errors.invalidCredentials }}
            </span>
          </div>
        </template>
      </v-card-text>

      <v-card-actions>
        <v-btn text="Close" @click="close"></v-btn>

        <v-spacer />

        <v-btn
          v-if="selectedModuleType == ModuleType.outscan && (isActivate || isEdit)"
          class="rounded"
          color="surface-variant"
          :loading="outscanLoading"
          :disabled="
            outscanLoading ||
            !(outscanConfig.credentials.username && outscanConfig.credentials.password)
          "
          text="Test credentials"
          variant="flat"
          @click="testOutscanCredentials"
        />
        <v-btn
          class="rounded"
          color="surface-variant"
          :loading="loading"
          :disabled="loading"
          text="Save"
          variant="flat"
          @click="submit(selectedModuleType)"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
  import { computed, onBeforeMount, PropType, ref, watch } from "vue";
  import { SwInput, SwSelect } from "sweepatic-shared/components";
  import { deepCopy } from "sweepatic-shared/utils";

  import { useStore } from "@/composables/store";
  import { reportSuccess } from "@/EventBus";
  import { outscanCheckCredentials } from "@/services/scopes.service";
  import {
    CredentialsConfig,
    LicenseDuration,
    ModuleConfig,
    ModuleType,
    OutscanConfig,
    PartnerContractType,
    ScopeModuleConfig,
    UpgradePayload,
  } from "@/typings";
  import { Bands, EDITABLE_MODULES, licenseDurations } from "./constants";

  const ERRORS = {
    license_duration_required: "Please select a duration for your scope",
    head_count_missing:
      "This scope has a head count based license. Please specify the number of employees of the client",
    head_count_not_valid: "Please enter a valid headcount value",
    band_required: "Please select a band",
    outscan_username: "Please enter the outscan username",
    outscan_password: "Please enter the outscan password",
    outscan_credentials: "Invalid credentials",
  };

  type Errors = {
    headCount?: string;
    licenseDuration?: string;
    band?: string;
    outscanUsername?: string;
    outscanPassword?: string;
    invalidCredentials?: string;
  };

  const $store = useStore();

  const $emit = defineEmits<{
    (
      e: "submit",
      moduleType: ModuleType,
      upgradePayload: UpgradePayload,
      configPayload: ModuleConfig | null,
    ): void;
    (e: "close"): void;
  }>();

  const props = defineProps({
    modules: { type: Object as PropType<ScopeModuleConfig>, required: true },
    open: { type: Boolean, required: true },
    selectedModuleType: { type: String as PropType<ModuleType>, required: true },
    loading: { type: Boolean, default: false },
    isEdit: { type: Boolean, default: false },
    isLicense: { type: Boolean, default: false },
    canUpgrade: { type: Boolean, default: false },
  });

  const licenseDuration = ref<LicenseDuration>();
  const headCount = ref<string>();
  const errors = ref<Errors>({});
  const outscanLoading = ref<boolean>(false);
  const credentialsConfig = ref<CredentialsConfig>({ band: undefined });
  const outscanConfig = ref<OutscanConfig>({ credentials: {} });

  const isActivate = computed<boolean>(() => {
    if (props.isLicense) {
      return !props.modules[props.selectedModuleType].licensed;
    }
    return !props.modules[props.selectedModuleType].enabled;
  });

  const isPartnerHeadCountBased = computed(() => $store.getters["session/isPartnerHeadCountBased"]);

  const partnerContractType = computed<PartnerContractType>(
    () => $store.getters["session/partnerContractType"],
  );

  onBeforeMount(async () => {
    await $store.dispatch("scopes/getBandLimits");
  });
  const selectedBand = ref<string>("");
  const bands = computed<Bands>(() => $store.getters["scopes/bands"]);

  const scopeTransfer = computed(() => $store.getters["session/scopeTransfer"]);

  const targetPartner = computed(() => (scopeTransfer.value ? scopeTransfer.value[0] : ""));

  const close = () => {
    $emit("close");
  };

  const validateForm = () => {
    errors.value = {};
    if (!props.modules) return false;
    if (!isActivate.value) return true;

    if (props.isLicense && props.canUpgrade) {
      if (!licenseDuration.value) {
        errors.value.licenseDuration = ERRORS.license_duration_required;
      }

      if (props.selectedModuleType === ModuleType.easm && isPartnerHeadCountBased.value) {
        const hc = Number(headCount.value);
        if (!hc) {
          errors.value.headCount = ERRORS.head_count_missing;
        }
        if (isNaN(hc) || hc <= 0) {
          errors.value.headCount = ERRORS.head_count_not_valid;
        }
      }
    } else if (
      props.selectedModuleType === ModuleType.credentials &&
      !credentialsConfig.value.band
    ) {
      errors.value.band = ERRORS.band_required;
    } else if (props.selectedModuleType === ModuleType.outscan) {
      if (!outscanConfig.value.credentials.username) {
        errors.value.outscanUsername = ERRORS.outscan_username;
      }
      if (!outscanConfig.value.credentials.password) {
        errors.value.outscanPassword = ERRORS.outscan_password;
      }
    }

    return Object.keys(errors.value).length === 0;
  };

  const testOutscanCredentials = async () => {
    const username = outscanConfig.value.credentials.username;
    const password = outscanConfig.value.credentials.password;

    if (!username || !password) {
      return;
    }

    outscanLoading.value = true;
    errors.value = {};
    try {
      await outscanCheckCredentials(username, password);
      reportSuccess("Correct Outscan credentials");
    } catch (err) {
      errors.value.invalidCredentials = ERRORS.outscan_credentials;
    } finally {
      outscanLoading.value = false;
    }
  };

  const submit = (moduleType: ModuleType) => {
    if (!props.modules || !validateForm()) {
      return;
    }

    const upgradePayload: UpgradePayload = {
      license_duration: licenseDuration.value as LicenseDuration,
      contract_type: partnerContractType.value,
      partner_name: $store.state.session?.session?.partner?.name ?? "",
    };

    if (headCount.value) {
      upgradePayload.head_count = +headCount.value;
    }

    if (targetPartner.value) {
      upgradePayload.target_partner = targetPartner.value;
    }

    let configPayload: ModuleConfig | null = null;
    switch (moduleType) {
      case ModuleType.credentials:
        configPayload = credentialsConfig.value;
        break;
      case ModuleType.outscan:
        configPayload = outscanConfig.value;
        break;
    }

    $emit("submit", moduleType, upgradePayload, configPayload);
  };

  watch(
    () => props.open,
    (val: boolean) => {
      if (val) {
        credentialsConfig.value = { band: selectedBand.value ? selectedBand.value : undefined };

        outscanConfig.value = {
          ...(props.modules.outscan?.config
            ? deepCopy<OutscanConfig>(props.modules.outscan.config)
            : { credentials: {} }),
        };
      } else {
        // makes it so that closing the dialog with esc doesn't break reopening it
        // because close with esc would not change open to false, so opening would work
        selectedBand.value = "";
        $emit("close");
      }
    },
    { immediate: true },
  );
  watch(selectedBand, () => {
    credentialsConfig.value = { band: selectedBand.value ? selectedBand.value : undefined };
  });
</script>
