import { LoadingState } from "@/typings/store/loading";
import { Getters, Mutations, Actions } from "@/typings/store";

const state: LoadingState = {
  isLoading: true,
};

const getters: Getters<LoadingState> = {
  isLoading: (state) => state.isLoading,
};

const mutations: Mutations<LoadingState> = {
  setLoading: (state, payload) => (state.isLoading = payload),
};

const actions: Actions<LoadingState> = {
  showLoading: ({ commit }) => commit("setLoading", true),

  hideLoading: ({ commit }) => commit("setLoading", false),
};

export default { namespaced: true, state, getters, mutations, actions };
