import {
  CheckWildcardReponse,
  GenericObject,
  GetLightDomainsRequest,
  NewScope,
  Scope,
  ScopeModule,
  ScopeModuleConfig,
  Scopes,
  ScopesRequestPayload,
  ScopeUser,
  UpdateScopeModule,
} from "@/typings";
import axios from "./axios";

export const getScopes = (partnerName: string, payload: ScopesRequestPayload) => {
  return axios.get<Scopes>(`/v1/partners/${partnerName}/scopes`, {
    params: payload,
  });
};

export const addScope = (payload: NewScope) => axios.post<NewScope>("/v1/scope/scopes", payload);

export const getScopeDetails = (partnerName: string, scope: string) => {
  return axios.get<Scope>(`/v1/partners/${partnerName}/${scope}`);
};

export const getScope = (scope: string) => {
  return axios.get<string | null>(`/v1/scope/scopes/${scope}`, { params: { partner: true } });
};

export const updateScopeLicense = (payload: GenericObject) => {
  return axios.patch("/v1/scope/license", payload);
};

export const checkWildcard = (domain: string) => {
  return axios.get<CheckWildcardReponse>("/v1/asset/seed/wildcard", {
    params: { domain, partner: true },
  });
};

export const getLightDomains = ({ domain, countOnly = false }: GetLightDomainsRequest) => {
  return axios.get("/v1/light/domains", { params: { domain, countOnly, partner: true } });
};

export const updateScope = (payload: GenericObject) => axios.patch("/v1/scope/manage", payload);

export const getScopeUsers = (scope: string) => {
  return axios.get<ScopeUser[]>(`/v1/user/scope_users/${scope}`);
};

export const getScopeModules = (scope: string, isCreate: boolean = false) => {
  return axios.get<ScopeModuleConfig>(`/v1/scope/${scope}/modules`, {
    params: { is_create: isCreate },
  });
};

export const outscanCheckCredentials = (username: string, password: string) => {
  return axios.post("/v1/outscan/check", { username, password });
};

export const updateScopeModule = (scope: string, payload: UpdateScopeModule) => {
  return axios.post<ScopeModule>(`/v1/scope/${scope}/modules`, payload);
};

export const updateScopeUser = (payload: GenericObject) => axios.patch("/v2/user/users", payload);

export const getBands = () => axios.get("/v1/bands");

export default {
  getScopes,
  addScope,
  getScopeDetails,
  getScope,
  updateScopeLicense,
  checkWildcard,
  getLightDomains,
  updateScope,
  getScopeUsers,
  getScopeModules,
  updateScopeModule,
  updateScopeUser,
};
