import { router } from "@/plugins/router";
import { FilterState, QuickFilter } from "@/typings/store/filter";
import { Getters, Mutations, Actions } from "@/typings/store";

const state: FilterState = {
  filter: "",
  quickFilters: {},
  historyKey: "",
  showGui: true,
  isPaginated: true,
  isSwitching: false,
  isLoading: false,
};

const mapQuickFiltersToFilterString = (quickFilters: QuickFilter) => {
  return Object.entries(quickFilters)
    .reduce((result, [key, filters]) => {
      if (!filters?.length) {
        return result;
      }

      return (result += ` + ${key} := ${filters.join(` , ${key} := `)}`);
    }, "")
    .substring(3);
};

const getters: Getters<FilterState> = {
  filter: (state) => state.filter,
  quickFilters: (state) => state.quickFilters,
  historyKey: (state) => state.historyKey,
  showGui: (state) => state.showGui,
  isSwitching: (state) => state.isSwitching,
  isLoading: (state) => state.isLoading,
};

const mutations: Mutations<FilterState> = {
  setFilter: (state, filter) => (state.filter = (filter || "").trim()),

  resetState: (state, { filter, historyKey, isPaginated }) => {
    state.filter = filter;
    state.historyKey = historyKey;
    state.isPaginated = isPaginated;
  },

  toggleFilterType: (state) => (state.showGui = !state.showGui),

  toggleIsSwitching: (state, payload) => (state.isSwitching = payload),

  setQuickFilters: (state, payload) => (state.quickFilters = payload),

  updateQuickFilter: (state, { field, filters }) => {
    if (filters?.length) {
      state.quickFilters = {
        ...state.quickFilters,
        [field]: filters,
      };
    } else {
      delete state.quickFilters[field];
    }
  },

  setLoading: (state, payload) => (state.isLoading = payload),
};

const actions: Actions<FilterState> = {
  search({ state, commit, dispatch }, filter) {
    let _filter = state.filter;

    if (filter) {
      _filter = filter;
      commit("setFilter", filter);
    }

    if (_filter) {
      router.updateQuery(state.isPaginated ? { filter: _filter, page: 1 } : { filter: _filter });
    } else {
      router.removeQuery("filter");
    }

    if (!filter && state.historyKey) {
      dispatch(
        "session/updateSearchHistory",
        { type: state.historyKey, entry: _filter },
        { root: true },
      );
    }
  },

  clear({ commit, state }) {
    router.updateQuery(state.isPaginated ? { page: 1, filter: undefined } : { filter: undefined });
    commit("setFilter", "");
  },

  quickFilterChange({ commit, state }, payload) {
    commit("updateQuickFilter", payload);

    if (!Object.keys(state.quickFilters).length) {
      router.removeQuery("quickFilters");
      return;
    }

    const queryString: { quickFilters: string; page?: number } = {
      quickFilters: mapQuickFiltersToFilterString(state.quickFilters),
    };

    if (state.isPaginated) {
      queryString.page = 1;
    }

    router.updateQuery(queryString);
  },

  setQuickFilters({ commit }, payload) {
    commit("setQuickFilters", payload);
    router.updateQuery({ quickFilters: mapQuickFiltersToFilterString(payload) });
  },
};

export default { namespaced: true, state, getters, mutations, actions };
