import { Partner } from "@/typings";
import axios from "./axios";

export const getPartners = () => axios.get<Partner[]>("/v1/partners");

export const setCurrentPartner = (partnerName: string) => {
  return axios.patch("/v1/partners/current_partner", { partner: partnerName });
};

export default { getPartners };
