export interface NewScope {
  name: string;
  seeds: string[];
  partner: boolean;
}

export interface CheckWildcardReponse {
  wildcard: boolean;
  domain: string;
}

export interface GetLightDomainsRequest {
  domain: string;
  countOnly: boolean;
}

export interface ScopeRole {
  scope: string;
  role: string;
}

export interface ScopeUser {
  disabled: boolean;
  email: string;
  firstName: string;
  lastName: string;
  scope: string;
  scope_admin: boolean;
  type: string;
  username: string;
  scope_roles: ScopeRole[];
}

export interface UpdateScopeModule {
  name: string;
  partner_name: string;
  module: ScopeModule;
  upgrade?: UpgradePayload;
}

export interface ScopeModule {
  enabled: boolean | null;
  licensed: boolean;
  config?: ModuleConfig;
}

export interface ScopeModuleWithName extends ScopeModule {
  name: string;
}

export type ModuleConfig = CredentialsConfig | OutscanConfig;

export interface CredentialsConfig {
  band?: string;
}

export interface OutscanConfig {
  credentials: {
    username?: string;
    password?: string;
  };
}

export enum ModuleType {
  files = "files", // read-only
  easm = "easm",
  credentials = "credentials",
  outscan = "outscan",
}

export type ScopeModuleConfig = Record<ModuleType, ScopeModule>;

export type LicenseDuration = "month" | "year";

export type PartnerContractType = "head_count_based" | "asset_size_based" | "flat";

export type UpgradePayload = {
  license_duration: LicenseDuration;
  contract_type: PartnerContractType;
  partner_name: string;
  head_count?: number;
  target_partner?: string;
};
