<template>
  <router-link
    v-if="item.to"
    :to="item.to"
    class="menu-item"
    :class="{ 'router-link-exact-active': isOnSubPage(item) }"
  >
    <sw-nav-bar-item-button :label="item.label" :icon="item.icon" />
  </router-link>

  <a v-else :href="item.href" target="_blank" rel="noopener noreferrer" class="menu-item">
    <sw-nav-bar-item-button :label="item.label" :icon="item.icon" />
  </a>
</template>

<script setup lang="ts">
  import { PropType } from "vue";
  import { useRoute } from "vue-router";

  import { MenuItem } from "@/typings/nav";
  import SwNavBarItemButton from "./SwNavbarItemButton.vue";

  defineProps({ item: { type: Object as PropType<MenuItem>, required: true } });

  const $route = useRoute();

  const isOnSubPage = ({ to, label }: MenuItem) => {
    // usesd label becasue scopes is now the home page
    return $route.path.startsWith(`${to}/`) || $route.path.includes(label.toLowerCase());
  };
</script>

<style lang="scss" scoped>
  .menu-item {
    &:not(:last-child) {
      margin-right: 8px;
    }

    &.router-link-exact-active {
      span {
        background: rgba(255, 255, 255, 0.2);
      }
    }

    span {
      @include text();
      color: #fff;
      padding: 8px 12px;
      display: block;
      cursor: pointer;
      border-radius: 6px;
      transition: 0.2s;

      &:hover {
        background: rgba(255, 255, 255, 0.2);
        transition: 0.2s;
      }
    }
  }
</style>
