/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

import { App } from "vue";
// add chartjs elements that are in use
import {
  Chart as ChartJS,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  PointElement,
} from "chart.js";

import vuetify from "./vuetify";
import createRouter from "./router";
import keycloak from "./keycloak";
import initTracker from "./openreplay";
import { store, storeKey } from "./store/store";

export async function registerPlugins(app: App) {
  const router = createRouter(store);

  app.use(vuetify).use(router).use(store, storeKey);

  initTracker();

  ChartJS.register(Title, Tooltip, Legend, LinearScale, PointElement, LineElement, CategoryScale);

  // @ts-ignore
  app.use(keycloak.plugin, keycloak.options(app, router, store));
}
