import emitter from "mitt";

import { GenericObject } from "./typings";
import { captureError } from "@/services/sentry.service";

export const EventBus = emitter<any>();

export const EVENTS = {
  TOAST: {
    ERROR: "error",
    INFO: "info",
    SUCCESS: "success",
  },
  PAGINATED: {
    RESET_DATATABLE: "paginated:resetDatatable",
    REACHED_END: "paginated:reachedEnd",
    PAGINATION_RESETTED: "paginated:paginationResetted",
  },
  SCOPE: {
    USER_CREATED: "scope:userCreated",
  },
  FILTER: {
    OUTSIDE: "filter:outsite",
    SEARCH_CLICK: "filter:searchClick",
    FILTER_SWITCH: "filter:filterSwitch",
    QUICK_FILTER_RESET: "filter:quickFilterReset",
  },
};

// TODO: move this to store
export const reportSuccess = (msg: string) => EventBus.emit(EVENTS.TOAST.SUCCESS, msg);

export function reportUnexpectedError(error: GenericObject, msg = "An unexpected error ocurred") {
  if (import.meta.env.DEV) {
    console.error("reportUnexpectedError: ", error);
  }

  EventBus.emit(EVENTS.TOAST.ERROR, msg);
  captureError(error);
}

export function reportError(msg: string, error?: any) {
  if (error?.response?.data) {
    const response = error.response;
    if (response.data.errors) {
      const descriptions = Object.values(response.data.errors).join("<br/>");
      EventBus.emit(EVENTS.TOAST.ERROR, `${msg}: ${descriptions}`);
      return;
    } else if (response.data.error) {
      EventBus.emit(EVENTS.TOAST.ERROR, `${msg}: ${response.data.error}`);
      return;
    }
  }
  EventBus.emit(EVENTS.TOAST.ERROR, `${msg}`);
}
