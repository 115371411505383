<template>
  <ul class="w-100" data-cy="manual-input">
    <li class="w-100 bg-white pl-1 ml-0">
      <input
        class="manual-input w-100"
        ref="input"
        :value="filter"
        :placeholder="placeholder"
        @change="onChange"
        @keypress.enter="onEnter"
      />
    </li>
  </ul>
</template>

<script lang="ts">
  // @ts-nocheck
  import { defineComponent } from "vue";
  import { mapActions, mapGetters, mapMutations } from "vuex";

  import { EventBus, EVENTS } from "@/EventBus";

  interface Methods {
    clear: () => void;
    search: () => void;
    setFilter: (filter: string) => void;
    onChange: (ev: Event) => void;
    onEnter: () => void;
  }

  interface Computed {
    filter: string;
  }

  interface Props {
    placeholder?: string;
  }

  export default defineComponent({
    name: "sw-filter-manual",
    props: {
      placeholder: { type: String, default: "Manual input" },
    },
    computed: {
      ...mapGetters("filter", ["filter"]),
    },
    methods: {
      ...mapActions("filter", ["clear", "search"]),
      ...mapMutations("filter", ["setFilter"]),
      onChange(ev) {
        const val = (ev.currentTarget as HTMLInputElement).value;
        this.setFilter(val);
        EventBus.emit(EVENTS.FILTER.OUTSIDE, val);
      },
      onEnter() {
        // force input change to trigger to update filter
        this.$refs.input.blur();
        this.search();
      },
    },
  });
</script>

<style lang="scss" scoped>
  input {
    outline: none;
    height: 36px;
    padding-right: 4px;
  }
</style>
