// progress bar on top of the page on evey axios request.
// heavily inspired by https://github.com/rikmms/progress-bar-4-axios
import nProgress from "nprogress";
import { Axios, AxiosProgressEvent } from "axios";

import { calcPercent } from "sweepatic-shared/utils";

export default (axios: Axios) => {
  const setupStartProgress = () => {
    axios.interceptors.request.use((config) => {
      nProgress.start();
      return config;
    });
  };

  const setupUpdateProgress = () => {
    const update = ({ loaded, total }: AxiosProgressEvent) => {
      return nProgress.inc(calcPercent(loaded, total ?? 0));
    };

    axios.defaults.onDownloadProgress = update;
    axios.defaults.onUploadProgress = update;
  };

  const setupStopProgress = () => {
    axios.interceptors.response.use(
      (res) => {
        nProgress.done();
        return res;
      },
      (err) => {
        nProgress.done();
        return Promise.reject(err);
      },
    );
  };

  nProgress.configure({
    showSpinner: false,
    minimum: 0.2,
    template: "<div class='nprogress-bar' role='bar'></div>",
  });
  setupStartProgress();
  setupUpdateProgress();
  setupStopProgress();
};
