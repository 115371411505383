import axios from "./axios";
import { LogsRequestPayload } from "@/typings/store/logs";



export default {
  getLogs: (params: LogsRequestPayload) => {
    return axios.get("/v1/logs", { params });
  },

  getTotalNumLogs: (params: LogsRequestPayload) => {
    return axios.get("/v1/logs", { params });
  },
};
