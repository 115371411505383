import { Getters, Mutations, Actions } from "@/typings/store";
import { LogsState } from "@/typings/store/logs";
import LogService from "@/services/logs.service";
import { RouterQuery } from "@/typings";

const state: LogsState = {
  logs: [],
  totalLogs: 0,
};

const getters: Getters<LogsState> = {
  logs: (state) => state.logs,
  totalLogs: (state) => state.totalLogs,
};

const mutations: Mutations<LogsState> = {
  setLogs: (state, { logs, total }) => {
    state.logs = logs;
    state.totalLogs = total;
  },
};

const actions: Actions<LogsState> = {
  async getLogs({ commit, rootGetters }, payload: RouterQuery) {
    const partnerName = rootGetters["session/partner"];

    const [logs, total] = await Promise.all([
      LogService.getLogs({
        partner_name: partnerName,
        ...payload,
        command: "get",
        type: "partner",
      }),
      LogService.getTotalNumLogs({
        partner_name: partnerName,
        ...payload,
        command: "total",
        type: "partner",
      }),
    ]);
    commit("setLogs", { logs, total });
  },
};

export default { namespaced: true, state, getters, mutations, actions };
