import { getLetterFromScore } from "sweepatic-shared/utils";

const INVALID_TLD = ["test", "example", "invalid", "localhost", "local"];
const RESERVED_DOMAINS = ["example.com", "example.net", "example.org"];
const DOMAIN_REGEX = /^[\w0-9-]+\.[\w0-9\-.]+$/i;

/**
 * Validates if a domain has a correct format,
 * valid TLD (e.g.: not .local) and not a reserved
 * domain (such as example.com)
 * @param {string} domain domain to check
 * @returns {boolean} true if valid, false if not
 */
export const isDomainValid = (domain: string) => {
  domain = domain.trim().toLowerCase();

  if (!DOMAIN_REGEX.test(domain)) {
    return false;
  }

  if (RESERVED_DOMAINS.includes(domain)) {
    return false;
  }

  if (domain.includes("in-addr.arpa")) {
    return false;
  }

  return !INVALID_TLD.includes(domain.split(".").reverse()[0]);
};

export const normalizeTextareaInput = (payload: string) => {
  const normalized = new Set();

  payload
    .replace(/\r?\n|\r|,/g, " ")
    .split(" ")
    .filter((bit) => bit)
    .forEach(normalized.add, normalized);

  return Array.from(normalized);
};

const SCORE_COLORS: Record<string, { color: string; background: string }> = {
  A: { color: "#10b981", background: "#10b98133" },
  B: { color: "#22c55e", background: "#22c55e33" },
  C: { color: "#eab308", background: "#eab30833" },
  D: { color: "#f59e0b", background: "#f59e0b33" },
  E: { color: "#f97316", background: "#f9731633" },
  F: { color: "#ef4444", background: "#ef444433" },
  "?": { color: "#4b5563", background: "#6b728033" },
};

/**
 * Gets text and background color associated with a score
 * @param {number | string} score score of the asset / scope. It's either the numeric value
 * in which case we fetch the letter first and then get the color, or it's already the
 * letter, and so we just get the color
 * @returns {color: string, background: string} score colors
 */
export const getScoreColor = (score: number | string): { color: string; background: string } => {
  return typeof score === "number" ? SCORE_COLORS[getLetterFromScore(score)] : SCORE_COLORS[score];
};
