import axios from "./axios";
import { Session } from "@/typings";

export const healthCheck = () => axios.get("/v1/health_check");

export const getSession = (command: string) => {
  return axios.get<Session>("/v1/session", { params: { command, partner: true } });
};

export default { getSession, healthCheck };
