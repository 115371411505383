<template>
  <v-list-item :title="label" @click="onClick">
    <template v-slot:prepend>
      <v-icon v-if="value" color="primary">fa-regular fa-toggle-on</v-icon>

      <v-icon v-else>fa-regular fa-toggle-off</v-icon>
    </template>
  </v-list-item>
</template>

<script lang="ts" setup>
  const $emit = defineEmits<{
    (e: "click:toggle", payload: boolean): void;
  }>();

  const props = defineProps({
    value: { type: Boolean, required: true },
    label: { type: String, required: true },
  });

  const onClick = () => $emit("click:toggle", !props.value);
</script>
