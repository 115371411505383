<template>
  <div data-cy="filter-gui" class="gui-wrapper">
    <v-menu
      offset-y
      content-class="menu-container"
      :model-value="isMenuVisible"
      :close-on-content-click="false"
      @update:model-value="onMenuToggle"
    >
      <template v-slot:activator="{ props }">
        <ul ref="ul">
          <li v-for="(item, idx) in currFilter" :key="idx" class="ml-1" data-cy="filter-slot">
            <v-chip
              label
              size="small"
              closable
              :color="getChipColor(item)"
              @click:close="removeChip(idx)"
            >
              {{ item }}
            </v-chip>
          </li>

          <li class="w-100">
            <input
              v-model="inputValue"
              type="text"
              v-bind="props"
              ref="input"
              class="pl-1 pr-1"
              :readonly="stage > 1"
              @keydown.enter="onInputKeyPress"
            />
          </li>
        </ul>
      </template>

      <!-- field selection -->
      <v-list
        v-show="stage === 1"
        density="compact"
        data-cy="filter-field-list"
        class="selection-list"
      >
        <v-list-item
          density="compact"
          v-for="(item, index) in filteredFields"
          :key="index"
          @click="selectField(item, 2)"
        >
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>

      <!-- second field selection, if applicable -->
      <v-list
        v-show="showSecondFieldSelection"
        density="compact"
        data-cy="filter-field-list"
        class="selection-list"
      >
        <v-list-item
          v-for="(item, index) in values[currFilterSection.text]"
          density="compact"
          :key="index"
          data-cy="selection-item"
          @click="selectField(item, currFilterSection.stages - 1)"
        >
          <v-list-item-title> {{ item }}</v-list-item-title>
        </v-list-item>
      </v-list>

      <!-- action selection -->
      <v-list
        v-show="showActionSelection"
        density="compact"
        data-cy="filter-action-list"
        class="selection-list"
      >
        <v-list-item
          density="compact"
          v-for="(item, index) in currActions"
          :key="index"
          @click="selectAction(item.text, currFilterSection.stages)"
        >
          <v-list-item-title class="d-flex justify-space-between">
            {{ item.text }}

            <span class="ml-3">{{ item.value }}</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>

      <!-- value selection -->
      <v-list
        v-if="currFilterSection"
        v-show="showValueSelection"
        density="compact"
        data-cy="filter-value-list"
        class="selection-list"
        :class="{ 'date-picker': inputType === 'date' }"
      >
        <template v-if="inputType === 'selection'">
          <template v-if="selectionValues">
            <v-list-item class="selection-filter">
              <input
                placeholder="Search..."
                ref="selectionInput"
                :value="selectionInputValue"
                @input="onSelectionValueChange"
              />
            </v-list-item>

            <v-list-item
              v-for="(item, index) in selectionValues"
              density="compact"
              :key="index"
              :title="item"
              data-cy="selection-item"
              @click="onStageUpdate(item, currFilterSection.stages + 1)"
            />
          </template>

          <v-list-item v-else density="compact">
            <v-list-item-title class="font-italic">Loading values...</v-list-item-title>
          </v-list-item>
        </template>

        <v-list-item v-show="inputType === 'free'" density="compact" data-cy="free-input">
          <input
            placeholder="Search by..."
            ref="freeInput"
            class="w-100"
            :value="freeInputvalue"
            @input="onFreeValueChange"
            @keydown.enter="setFreeInputValue(currFilterSection.stages + 1)"
          />

          <template v-slot:append>
            <v-btn
              size="x-small"
              variant="plain"
              @click="setFreeInputValue(currFilterSection.stages + 1)"
            >
              <v-icon size="large">fa-regular fa-angle-right</v-icon>
            </v-btn>
          </template>
        </v-list-item>

        <v-list-item v-show="inputType === 'date'" density="compact" data-cy="date-input">
          <v-date-picker
            :model-value="dateValue"
            landscape
            title=""
            hide-header
            color="primary"
            first-day-of-week="1"
            @update:model-value="onDateSelect"
          />
        </v-list-item>
      </v-list>

      <!-- combinator selection -->
      <v-list
        v-show="showCombinatorSelection"
        density="compact"
        data-cy="filter-combinator-list"
        class="selection-list"
      >
        <v-list-item
          density="compact"
          v-for="(item, index) in combinators"
          :key="index"
          @click="onStageUpdate(item.text, 1)"
        >
          <v-list-item-title class="d-flex justify-space-between">
            {{ item.text }}

            <span class="ml-3">{{ item.value }}</span>
          </v-list-item-title>
        </v-list-item>

        <v-divider />

        <v-list-item density="compact" @click="onSearchClick"> Search </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script lang="ts">
  // @ts-nocheck
  import { defineComponent, PropType } from "vue";
  import { mapActions, mapGetters, mapMutations } from "vuex";
  import type { Dayjs } from "sweepatic-shared/utils/date";

  import { GuiField, GuiValue, GuiFieldInputType } from "@/typings/filter";
  import { EventBus, EVENTS } from "@/EventBus";

  interface ActionObject {
    text: string;
    value: string;
  }

  interface Data {
    stage: number;
    currActions: ActionObject[];
    combinators: ActionObject[];
    currFilter: string[];
    selectionInputValue: string;
    freeInputvalue: string | Date;
    inputType: GuiFieldInputType;
    currFilterSection: GuiField;
    isMenuVisible: boolean;
    inputValue: string;
    dateValue: string;
  }

  interface Computed {
    filter: string;
    filteredFields: GuiField[];
    selectionValues: string[];
    showSecondFieldSelection: boolean;
    showActionSelection: boolean;
    showValueSelection: boolean;
    showCombinatorSelection: boolean;
  }

  interface Methods {
    selectField: (payload: GuiField, stage: number) => void;
    selectAction: (action: string, stage: number) => void;
    onStageUpdate: (payload: string, stage: number) => void;
    onSelectionValueChange: (ev: InputEvent) => void;
    onFreeValueChange: (ev: InputEvent) => void;
    setFreeInputValue: (stage: number, text?: string) => void;
    removeChip: (idx: number) => void;
    updatePosition: () => void;
    getChipColor: (payload: string) => string;
    onMenuToggle: (val: boolean) => void;
    textToGui: (filter: string) => void;
    guiToText: () => string;
    scrollToInputEnd: () => void;
    onInputKeyPress: (ev: Event) => void;
    search: () => void;
    setFilter: (payload: string) => void;
    updateFilter: (newVal: string | string[]) => void;
    validateStage: (currFilter: string[]) => void;
    onSearchClick: () => void;
    onDateSelect: (payload: string) => void;
    updateFilterFromOutside: (filter: string) => void;
    toggleOffMenu: () => void;
  }

  interface Props {
    fields: GuiField[];
    values: GuiValue;
  }

  let menuElem = null;

  const ACTION_TEXT = ["equals", "not equals", "contains", "greater than", "lower than"];

  const ACTIONS = {
    equals: { text: "Equals", value: ":=" },
    not_equals: { text: "Not equals", value: ":!=" },
    contains: { text: "Contains", value: ":~" },
    not_contains: { text: "Not contains", value: ":!~" },
    greater_than: { text: "Greater than", value: ":>" },
    lower_than: { text: "Lower than", value: ":<" },
  };

  const OPERATOR_TO_TEXT = {
    "+": "AND",
    ":=": "Equals",
    ":~": "Contains",
    ":!=": "Not equals",
    ":!~": "Not contains",
    ":>": "Greater than",
    ":<": "Lower than",
  };

  const TEXT_TO_OPERATOR = {
    AND: " +",
    OR: ", ",
    Equals: " :=",
    Contains: " :~",
    "Not equals": " :!=",
    "Not contains": " :!~",
    "Greater than": " :>",
    "Lower than": " :<",
  };

  export default defineComponent({
    name: "sw-filter-gui",
    props: {
      fields: { type: Array as PropType<Array<GuiField>>, required: true },
      values: { type: Object as PropType<GuiValue>, required: true },
    },
    data() {
      return {
        stage: 1,
        combinators: [
          { text: "AND", value: "+" },
          { text: "OR", value: "," },
        ],
        selectionInputValue: "",
        freeInputvalue: "",
        dateValue: "",
        inputType: "selection",
        isMenuVisible: false,
        inputValue: "",
        currFilter: [],
        currActions: [],
        currFilterSection: {
          actions: {},
          text: "",
          stages: 3,
        },
      };
    },
    watch: {
      filter(filter) {
        if (!filter) {
          this.currFilter = [];
          this.stage = 1;
          this.updatePosition();
        }
      },
      showGui(val) {
        if (val) {
          this.updatePosition();
        }
      },
    },
    computed: {
      ...mapGetters("filter", ["filter", "showGui"]),
      filteredFields() {
        const val = this.inputValue.trim().toLowerCase();

        return !val
          ? this.fields
          : this.fields.filter(({ text }) => text.toLowerCase().includes(val));
      },
      selectionValues() {
        if (
          !this.currFilterSection ||
          !this.currFilterSection.text ||
          !this.values[this.currFilterSection.text]
        ) {
          return [];
        }

        const filter = this.selectionInputValue.toLowerCase();

        return this.values[this.currFilterSection.text].filter((val) =>
          val.toLowerCase().includes(filter),
        );
      },
      showSecondFieldSelection() {
        return this.stage === 2 && this.currFilterSection.stages != 3;
      },
      showActionSelection() {
        return (
          (this.stage === 2 && this.currFilterSection.stages === 3) ||
          (this.stage === 3 && this.currFilterSection.stages === 4)
        );
      },
      showValueSelection() {
        return (
          (this.stage === 3 && this.currFilterSection.stages === 3) ||
          (this.stage === 4 && this.currFilterSection.stages === 4)
        );
      },
      showCombinatorSelection() {
        return (
          (this.stage === 4 && this.currFilterSection.stages === 3) ||
          (this.stage === 5 && this.currFilterSection.stages === 4)
        );
      },
    },
    mounted() {
      EventBus.on(EVENTS.FILTER.OUTSIDE, this.updateFilterFromOutside);
      EventBus.on(EVENTS.FILTER.SEARCH_CLICK, this.onSearchClick);
      EventBus.on(EVENTS.FILTER.FILTER_SWITCH, this.toggleOffMenu);
      document.querySelector("#main-container")?.addEventListener("scroll", this.toggleOffMenu);

      menuElem = document.querySelector(".menu-container");

      this.isMenuVisible = false;

      if (!this.filter) {
        return;
      }

      this.textToGui(this.filter);
      this.updateFilter(this.filter);

      this.$nextTick(() => {
        this.updatePosition();

        this.validateStage(this.currFilter);

        // gets the field of the last item in the expression
        // so that if user deletes value or action the lists show up
        // correctly. For that, we get the difference between current stage and stage 1
        // which we then use to get field the position in currFilter array
        const field = this.currFilter[this.currFilter.length - (this.stage - 1)];

        // and then get the field from fields array where text matches field in filter
        const currField = this.fields.find((f: GuiField) => f?.text === field);

        // means it's a free text filtering, this isn't necessary
        if (currField) {
          this.currFilterSection = currField;
          this.currActions = Object.keys(currField.actions).map((a) => ACTIONS[a]);
        }

        this.updatePosition();
      });
    },
    beforeUnmount() {
      EventBus.off(EVENTS.FILTER.OUTSIDE, this.updateFilterFromOutside);
      EventBus.off(EVENTS.FILTER.SEARCH_CLICK, this.onSearchClick);
      EventBus.off(EVENTS.FILTER.FILTER_SWITCH, this.toggleOffMenu);
      document.querySelector("#main-container").removeEventListener("scroll", this.toggleOffMenu);
    },
    methods: {
      ...mapActions("filter", ["search"]),
      ...mapMutations("filter", ["setFilter"]),
      selectField(payload: GuiField, stage: number) {
        let stagePayload = payload;
        let guiFieldPayload = null;
        if (typeof payload !== "string") {
          if (payload.actions) {
            this.currActions = Object.keys(payload.actions).map((a) => ACTIONS[a]);
          }
          guiFieldPayload = payload.text;
          this.currFilterSection = payload;
        }
        this.onStageUpdate(guiFieldPayload || stagePayload, stage);

        this.selectionInputValue = "";
        this.freeInputvalue = "";
        this.inputValue = "";
      },
      selectAction(action: string, stage: number) {
        this.onStageUpdate(action, stage);

        action = action.replaceAll(" ", "_").toLowerCase();

        this.inputType = this.currFilterSection.actions[action];

        if (this.inputType === "selection") {
          this.$nextTick(() => this.$refs.selectionInput.focus());
        }

        if (this.inputType === "free") {
          this.$nextTick(() => this.$refs.freeInput.focus());
        }
      },
      onStageUpdate(payload: string, stage: number) {
        this.stage = stage;

        this.currFilter.push(payload.trim());

        this.setFilter(this.guiToText());

        this.updatePosition();
        this.selectionInputValue = "";
        this.$refs.input.focus();
      },
      onSelectionValueChange(ev) {
        const text = ((ev.target as HTMLInputElement).value || "").trim();

        this.selectionInputValue = text;
      },
      onFreeValueChange(ev) {
        const text = ((ev.target as HTMLInputElement).value || "").trim();

        this.freeInputvalue = text;

        if (["  ", ". "].includes(text.substring(-2))) {
          this.setFreeInputValue(text);
        }
      },
      setFreeInputValue(stage = 4, text = this.freeInputvalue) {
        // sanitizes user input to add \ to special characters so we know
        // that these characters are part of the value and not to be parsed
        const val = (text || "").trim().replace(/[,*+?^${}()|[\]\\]/g, "\\$&");

        if (val) {
          this.onStageUpdate(val, stage);
        }
      },
      onDateSelect(text: Dayjs) {
        if (text) {
          this.onStageUpdate(text.format("YYYY-MM-DD"), this.currFilterSection.stages + 1);
        }
      },
      removeChip(idx: number) {
        this.currFilter = this.currFilter.slice(0, idx);

        this.$nextTick(() => {
          this.validateStage(this.currFilter);
          // makes it so menu is rendered in the correct spot
          this.$refs.input.click();
        });

        if (!this.currFilter.length) {
          this.$router.removeQuery("filter");
          this.isMenuVisible = false;
        }

        this.freeInputvalue = "";
        this.selectionInputValue = "";

        this.setFilter(this.guiToText());
      },
      async updatePosition() {
        // let next tick run to make sure html has been updated, otherwise the dropdown will be one chip behind
        await this.$nextTick();

        this.scrollToInputEnd();

        if (menuElem) {
          menuElem.style.left = `${this.$refs.input?.getBoundingClientRect().left}px`;
        }
      },
      getChipColor(payload: string) {
        return ["AND", "OR"].includes(payload) ? "primary" : "default";
      },
      // needs to run the first time menu is opened to get element and to
      // update position for the first time if there's history
      onMenuToggle(val: boolean) {
        this.isMenuVisible = val;

        if (val) {
          this.$nextTick(this.updatePosition);
        }
      },
      textToGui(filter: string = this.filter) {
        if (!filter) {
          return [];
        }

        // since it's possible for search values to be strings with spaces in them it will happen
        // that this will split one value into several chips, which is wrong. This flag is used
        // to control that so if previous item in the array is not a keyword, it means we're building
        // a string with spaces, so should add to the previous entry and not make a new one
        let isPrevAKeyword = true;

        // it's possible that the filter is one with 4 stages. The filter will be saved or processed
        // as a filter with 3 stages like ex "x := y". But because it originally had 4 stages, the field
        // is made out of a <main_field> and a <sub_field>, so for ex "x" is actually "a.b" as a string
        // we need to make sure that this function actually will separate a and b in different parts.
        // if we find the <main_field>, this flag will turn to false and then we'll know that the next item
        // is a <sub_field>.
        let isPrevAMainField = false;

        // if DNS. is in the filter, replace the . with a space char
        if (filter.includes("DNS.")) {
          filter = filter.replace("DNS.", "DNS ");
        }
        return filter.split(" ").reduce((result: string[], item: string) => {
          item = item.trim();

          if (isPrevAMainField) {
            if (item !== "Type") {
              isPrevAMainField = false;
              result.push(item);
              return result;
            } else {
              isPrevAMainField = false;
            }
          }

          if (item === "DNS") {
            isPrevAMainField = true;
          }

          if (!item) {
            return result;
          }

          if (item.endsWith(",")) {
            if (item.endsWith("\\,")) {
              result[result.length - 1] += ` ${item.replace("\\,", ",")}`;
              isPrevAKeyword = false;
              return result;
            }

            if (isPrevAKeyword) {
              result.push(item.substring(0, item.length - 1));
            } else {
              result[result.length - 1] += ` ${item.substring(0, item.length - 1)}`;
            }

            result.push("OR");
            isPrevAKeyword = true;
            return result;
          }

          if (OPERATOR_TO_TEXT[item]) {
            result.push(OPERATOR_TO_TEXT[item]);
            isPrevAKeyword = true;
            return result;
          }

          if (isPrevAKeyword) {
            result.push(item);
          } else {
            result[result.length - 1] += ` ${item}`;
          }

          isPrevAKeyword = false;
          return result;
        }, []);
      },
      guiToText(filter: string[] = this.currFilter) {
        let addDot = false;
        return filter.reduce((result: string, item: string) => {
          const action = TEXT_TO_OPERATOR[item];
          let _item = item;

          if (item.includes(",") && !item.includes("\\,")) {
            _item = item.replace(",", "\\,");
          }

          if (addDot) {
            addDot = false;
            return `${result}.${_item}`;
          }
          if (_item === "DNS") {
            addDot = true;
          }

          return action ? `${result}${action}` : `${result} ${_item}`;
        }, "");
      },
      scrollToInputEnd() {
        this.$refs.ul.scrollLeft += this.$refs.ul.scrollWidth;
      },
      onInputKeyPress(ev: Event) {
        ev.stopImmediatePropagation();
        this.isMenuVisible = false;

        const val = this.inputValue.trim();

        if (this.stage === 1 && val) {
          this.$refs.input.blur();
          this.currFilter.push(val);
          this.setFilter(this.guiToText());
          this.stage = 4;
        }

        this.inputValue = "";
        this.selectionInputValue = "";
        this.freeInputvalue = "";
        this.dateValue = new Date().toISOString();
        this.search();
      },
      updateFilter(newVal: string | string[]) {
        let local: string | string[];
        let store: string | string[];

        if (Array.isArray(newVal)) {
          local = newVal;
          store = this.guiToText(newVal);
        } else {
          local = this.textToGui(newVal);
          store = newVal;
        }

        this.setFilter(store);
        this.currFilter = local;
      },
      validateStage(currFilter: string[]) {
        // a search filter can have 3 or 4 stages
        // if 3 stages, after chip removal:
        //    lastItem will contain the action
        //    lastLastItem will contain the field
        //    lastLastLastItem should be undefined
        // and so on, it offsets for every chip removal
        //
        // if 4 stages, after chip removal:
        //    lastItem will contain the action
        //    lastLastItem will contain the secondary field
        //    lastLastLastItem will contain the field itself
        // and so on, it offsets for every chip removal
        const getInputType = (actions, lastItem) => {
          return actions[lastItem] || actions[lastItem.replace(" ", "_")];
        };

        const lastItem = (currFilter[this.currFilter.length - 1] || "").toLowerCase();
        const lastLastItem = (currFilter[this.currFilter.length - 2] || "").toLowerCase();
        const lastLastLastItem = (currFilter[this.currFilter.length - 3] || "").toLowerCase();
        const lastLastLastLastItem = (currFilter[this.currFilter.length - 4] || "").toLowerCase();

        if (!lastItem || ["and", "or"].includes(lastItem)) {
          this.stage = 1;
          return;
        }

        let currField = this.fields.find(({ text }) => text.toLowerCase() === lastItem);

        if (currField) {
          this.stage = 2;
          this.currFilterSection = currField;
          this.inputType = getInputType(currField.actions, lastItem);
          this.currActions = Object.keys(currField.actions).map((a) => ACTIONS[a]);
          return;
        }

        currField = this.fields.find(({ text }) => text.toLowerCase() === lastLastItem);
        if (currField) {
          this.stage = 3;
          this.currFilterSection = currField;
          this.inputType = getInputType(currField.actions, lastItem);
          return;
        }

        currField = this.fields.find(({ text }) => text.toLowerCase() === lastLastLastLastItem);
        if (currField) {
          this.stage = 5;
          this.currFilterSection = currField;
          this.currActions = Object.keys(currField.actions).map((a) => ACTIONS[a]);
          return;
        }

        if (ACTION_TEXT.includes(lastItem)) {
          currField = this.fields.find(({ text }) => text.toLowerCase() === lastLastItem);
          if (currField) {
            this.stage = 3;
            this.currFilterSection = currField;
            this.inputType = getInputType(currField.actions, lastItem);
            return;
          }

          currField = this.fields.find(({ text }) => text.toLowerCase() === lastLastLastItem);
          if (currField) {
            this.stage = 4;
            this.currFilterSection = currField;
            this.inputType = getInputType(currField.actions, lastItem);
            return;
          }
        }

        if (ACTION_TEXT.includes(lastLastItem)) {
          currField = this.fields.find(({ text }) => text.toLowerCase() === lastLastLastItem);
          if (currField) {
            this.stage = 4;
            this.currFilterSection = currField;
            this.currActions = Object.keys(currField.actions).map((a) => ACTIONS[a]);
            this.inputType = currField.actions[lastLastItem];
            return;
          }
        }

        this.stage = 5;
      },
      onSearchClick() {
        this.isMenuVisible = false;
        this.selectionInputValue = "";
        this.freeInputvalue = "";
        this.dateValue = new Date().toISOString();
        this.search();
      },
      // triggered when filter is updated in manual, by recent searches or clicking on table icon
      updateFilterFromOutside(filter: string) {
        const current = this.textToGui(filter);

        this.currFilter = current;
        this.selectionInputValue = "";
        this.freeInputvalue = "";
        this.dateValue = new Date().toISOString();
        this.validateStage(current);
      },
      toggleOffMenu() {
        this.isMenuVisible = false;
      },
    },
  });
</script>

<style lang="scss" scoped>
  .gui-wrapper {
    height: 36px;
    width: 100%;
    overflow-x: auto;
    cursor: pointer;
  }

  .menu-container {
    max-height: 300px;
    min-width: 0 !important;

    .v-list-item__title {
      overflow: initial;
      white-space: initial;
    }

    .d-flex {
      background: #fff;
      padding-left: 8px;

      input {
        width: 100%;
      }

      button {
        border-left: 1px solid grey;
      }
    }

    span {
      opacity: 0.5;
    }
  }

  input {
    outline: none;
  }

  .selection-filter {
    font-size: 14px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .v-list {
    &.date-picker {
      width: 380px;
      overflow: hidden;
    }
  }

  .selection-list {
    max-height: 400px;
  }
</style>
