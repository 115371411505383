import { useStore } from "@/composables/store";
import { QuickFilter, QuickFilterConfigObj } from "@/typings";

export const useQuickFilters = () => {
  const $store = useStore();

  const mapQuickFilterOptionToFilter = (items: QuickFilterConfigObj[]): string[] => {
    return items.reduce((result: string[], item) => {
      if (item.default) {
        result.push(item.value);
      }

      return result;
    }, []);
  };

  const mapQuickFiltersFromQuery = (queryString: string): QuickFilter => {
    let bit: string;

    return queryString.split(" + ").reduce((result: QuickFilter, current: string) => {
      for (bit of current.split(" , ")) {
        const [field, value] = bit.split(" := ");

        if (!result[field]) {
          result[field] = [value];
        } else {
          result[field].push(value);
        }
      }

      return result;
    }, {});
  };

  const setQuickFiltersFromQuery = (queryString: string): void => {
    $store.dispatch("filter/setQuickFilters", mapQuickFiltersFromQuery(queryString));
  };

  const getDefaultQuickFilterConfigs = (config: QuickFilterConfigObj[]): string[] => {
    return config.reduce((result, item: QuickFilterConfigObj) => {
      if (item.default) {
        result.push(item.value);
      }

      return result;
    }, [] as string[]);
  };

  const hasDefaultQuickFilters = (configs: { [key: string]: string[] }, qs: string) => {
    // @ts-ignore - dunno how to type vuex functions in mixins
    const mappedQs = mapQuickFiltersFromQuery(qs);

    return Object.entries(configs).every(([key, val]) => {
      if (!mappedQs[key]) {
        return true;
      }

      if (mappedQs[key].length !== val.length) {
        return false;
      }

      return mappedQs[key].every((item: string) => val.includes(item));
    });
  };

  return { mapQuickFilterOptionToFilter, hasDefaultQuickFilters, getDefaultQuickFilterConfigs };
};
