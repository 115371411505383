<template>
  <v-menu offset-y open-on-click min-width="200" @click.stop.prevent>
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" elevation="0" class="ml-1 rounded" color="primary" height="40px">
        <v-icon size="small">fa-regular fa-bars</v-icon>
      </v-btn>
    </template>

    <v-list>
      <slot name="actions" />

      <template v-if="hasSettingsSlot">
        <v-divider class="mx-5" />

        <slot name="settings" />
      </template>
    </v-list>
  </v-menu>
</template>

<script lang="ts" setup>
  import { useSlots } from "vue";
  import { ref } from "vue";

  const hasSettingsSlot = ref(!!useSlots().settings);
</script>
